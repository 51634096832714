import { Button, Card } from "antd";
import React from "react";
import { useSearchParams } from "react-router-dom";

import { createValidUrl } from "../../../utils/commonFuctions";
import { replaceMentions } from "../../../utils/suggestions";

const ProposalFinancing = ({
  settings,
  proposalDetails,
  generatingPdf,
  userDetails,
}) => {
  const [params] = useSearchParams();
  const token = params.get("token");
  const RenderContent = () => (
    <>
      <div className="contract-bg-section my-5">
        <div className="d-flex align-items-center gap-3 justify-content-between pe-4">
          <div
            className="abouts-btn"
            style={{
              backgroundColor: settings?.templateColor,
              color: settings?.templateTextColor,
              padding: "20px 60px",
              ...(generatingPdf && {
                fontSize: "35px",
                fontWeight: "bold",
              }),
            }}
          >
            Financing
          </div>
          {!settings?.proposedSchedule &&
            (userDetails?.organization?.logo ||
              proposalDetails?.organizationDetails?.logo) && (
              <div className="ms-auto">
                <img
                  src={createValidUrl(
                    token
                      ? proposalDetails?.organizationDetails?.logo
                      : userDetails?.organization?.logo,
                  )}
                  alt="financing"
                  className="company-logo"
                />
              </div>
            )}
        </div>
      </div>
      <div className="mt-5 ad-contract">
        <p
          dangerouslySetInnerHTML={{
            __html: replaceMentions(
              proposalDetails?.financing?.description,
              proposalDetails?.customerDetails,
              token
                ? proposalDetails?.organizationDetails
                : userDetails?.organization,
              token ? proposalDetails?.userDetails : userDetails,
            ),
          }}
        />
      </div>
      {proposalDetails?.financing?.link &&
        (generatingPdf ? (
          <div style={{ height: 50 }} />
        ) : (
          <div className="px-5">
            <Button
              className="apply-btn financing"
              type="primary"
              style={{
                backgroundColor: settings?.templateColor,
                color: settings?.templateTextColor,
              }}
              onClick={() =>
                window.open(
                  proposalDetails?.financing?.link?.includes("https") ||
                    proposalDetails?.financing?.link?.includes("http")
                    ? proposalDetails?.financing?.link
                    : `https://${proposalDetails?.financing?.link}`,
                  "_blank",
                )
              }
            >
              Apply For Financing
            </Button>
          </div>
        ))}
    </>
  );
  return settings?.proposedSchedule ? (
    <RenderContent />
  ) : (
    <Card
      bordered={false}
      style={{
        borderRadius: "12px",
        boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
      }}
      className="mb-3 proposal-card-h-fix"
    >
      <RenderContent />
    </Card>
  );
};
export default ProposalFinancing;
