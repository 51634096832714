/* eslint-disable no-unused-vars */
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Button, Col, Form, Input, message, Row, Select } from "antd";
import TagInput from "antd-tag-input";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { useEffect, useRef, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { useDispatch, useSelector } from "react-redux";

import { viewedEstimation } from "../../../../api/contract";
import { sendProposalToCustomer } from "../../../../api/proposal";
import ModalDailog from "../../../../common/components/ModalDialogue";
import { useEmailTemplates } from "../../../../common/hooks";
import { getEmailTemplatesAction } from "../../../../redux/invoice/invoice.action";
import { createValidUrl } from "../../../../utils/commonFuctions";
import { ATTACHMENT_TYPES } from "../../../../utils/constants/constants";
import regex from "../../../../utils/regex";
import {
  mentions,
  replacePatterns,
  replaceWithoutTagValue,
} from "../../../../utils/suggestions";

const SendProposalToCustomer = ({
  templateName,
  onClose,
  estimationId,
  generatingPdf,
  sendingEmail,
  setSendingEmail,
  files,
  setFiles,
  fileSizeError,
  setFileSizeError,
  validFileError,
  setValidFileError,
  proposalId,
  getProposalSettings,
  proposalDetails,
}) => {
  const dispatch = useDispatch();
  const customerDetails = useSelector((state) => state.lead.leadInfo);
  const organizationDetails = useSelector((state) => state.organization);
  const { oldEmailTemplate, getOldEmailTemplate } = useEmailTemplates();
  const [signature, setSignature] = useState(EditorState.createEmpty());
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [template, setTemplate] = useState({});
  const [customerEmails, setCustomerEmails] = useState([]);
  const inputRef = useRef(null);
  const { emailCategory } = useSelector((state) => state?.invoice);
  const [viewed, setViewed] = useState(false);
  const [callingView, setCallingView] = useState(false);
  const isEstimationViewed = localStorage.getItem(estimationId);

  const handleRemoveFile = (ind) => {
    const clonedFile = [...files];
    clonedFile.splice(ind, 1);
    setFiles([...clonedFile]);
    const newFiles = new DataTransfer();
    clonedFile?.forEach((file) => newFiles?.items.add(file));
    inputRef.current.files = newFiles.files;
  };

  const handleTemplateChange = (id) => {
    const template = oldEmailTemplate.find((item) => item._id === id);
    if (template) {
      setTemplate({
        ...(template || {}),
        originalSubject: template.subject || "",
      });
      const templateBody = htmlToDraft(template.body || "");
      const contentState = ContentState.createFromBlockArray(
        templateBody.contentBlocks,
      );
      setEditorState(EditorState.createWithContent(contentState));
      const templateSignature = htmlToDraft(template.signature || "");
      const contentStateForsignature = ContentState.createFromBlockArray(
        templateSignature.contentBlocks,
      );
      setSignature(EditorState.createWithContent(contentStateForsignature));
    }
  };

  const handleSendMail = async () => {
    if (!customerEmails.length) {
      message.error("Customer Email is required");
      return;
    }

    setSendingEmail(true);

    // Optimize function to replace patterns

    const body = replaceWithoutTagValue(
      draftToHtml(convertToRaw(editorState.getCurrentContent())),
    );
    const newSignature = replaceWithoutTagValue(
      draftToHtml(convertToRaw(signature.getCurrentContent())),
    );
    template.signature = newSignature;
    if (template.signature) {
      const formData = new FormData();
      formData.append("estimateId", estimationId);
      formData.append("template", JSON.stringify(template));
      formData.append("leadEmails", JSON.stringify(customerEmails));
      formData.append("body", body);
      formData.append("proposalId", proposalId);
      if (files?.length) {
        files.forEach((file) => {
          formData.append("attachment", file);
        });
      }
      const res = await sendProposalToCustomer(formData);
      if (res.remote === "success") {
        onClose();
        message.success("Email Sent Successfully");
        setFileSizeError(false);
        setValidFileError(false);
        getProposalSettings(estimationId);
      } else {
        console.log("first => ", res?.errors?.errors);
        message.error(
          res?.errors?.errors?.error ||
            res?.errors?.errors?.message ||
            res?.errors?.errors,
        );
        message.error(res?.errors?.errors?.error);
      }
    } else {
      message.error("Please add contractor signature");
    }
    setSendingEmail(false);
  };

  const handleAttachFile = (e) => {
    const { files: targetFiles } = e?.target;
    const fileList = [...targetFiles];
    if (targetFiles) setFiles(fileList);
    const clonedFiles = [...files];
    if (clonedFiles?.length) {
      clonedFiles?.push(...fileList);
      const newDataTransfer = new DataTransfer();
      clonedFiles?.forEach((file) => newDataTransfer.items.add(file));
      inputRef.current.files = newDataTransfer?.files;
      setFiles([...clonedFiles]);
    }
    const totalFileSize = fileList?.reduce((acc, curr) => acc + curr?.size, 0);
    const maxSizeInMb = 25 * 1024 ** 2;
    const validateFiles = (fileList) =>
      fileList.every((file) =>
        ATTACHMENT_TYPES.some((fileType) => file?.type?.includes(fileType)),
      );
    if (totalFileSize > maxSizeInMb) {
      setFileSizeError(true);
    } else {
      setFileSizeError(false);
    }
    if (!validateFiles(fileList)) {
      setValidFileError(true);
    } else {
      setValidFileError(false);
    }
    inputRef.current.files = "";
  };

  const viewEstimate = async () => {
    setCallingView(true);
    setViewed(false);
    if (estimationId && !isEstimationViewed) {
      const response = await viewedEstimation(estimationId);
      if (response.remote === "success") {
        localStorage.setItem(estimationId, "true");
      }
      setCallingView(false);
    }
  };

  useEffect(() => {
    if (customerDetails && customerDetails.email) {
      setCustomerEmails([customerDetails.email]);
    }
  }, [customerDetails, customerDetails.email]);

  useEffect(() => {
    getOldEmailTemplate();
  }, [getOldEmailTemplate]);

  useEffect(() => {
    if (oldEmailTemplate) {
      const template = oldEmailTemplate.find(
        (item) =>
          item.category === templateName && item.defaultEnabled === true,
      );
      if (template) {
        setTemplate({
          ...(template || {}),
          originalSubject: template.subject || "",
        });
        const templateBody = htmlToDraft(template.body || "");
        const contentState = ContentState.createFromBlockArray(
          templateBody.contentBlocks,
        );
        setEditorState(EditorState.createWithContent(contentState));
        const templateSignature = htmlToDraft(template.signature || "");
        const contentStateForsignature = ContentState.createFromBlockArray(
          templateSignature.contentBlocks,
        );
        setSignature(EditorState.createWithContent(contentStateForsignature));
      }
    }
  }, [oldEmailTemplate, templateName]);

  useEffect(() => {
    if (templateName) {
      dispatch(getEmailTemplatesAction(templateName));
    }
  }, [templateName]);

  return (
    <>
      <Tab.Container id="left-tabs-example">
        <div className="custom-catlog-tabs">
          <Nav className="catlog-tabs mx-0 pt-0" as="ul">
            <Nav.Item as="li">
              <Nav.Link className="active" eventKey="Template1">
                <b className="left-curve"></b>
                <b className="right-curve"></b>
                <p>{template?.name}</p>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <Tab.Content className="notifation-template-panel">
          <Tab.Pane eventKey="Template1" className="active">
            <Form className="pt-3">
              <div className="form-group">
                <label htmlFor="">Lead&lsquo;s Email:</label>
                <TagInput
                  value={customerEmails}
                  placeholder="Enter Emails"
                  onChange={(strArr) => {
                    const newEmail = strArr[strArr.length - 1];
                    if (regex.emailRegex.test(newEmail) || !strArr.length) {
                      setCustomerEmails(strArr);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 9) e.preventDefault();
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">Email Subject:</label>
                <Input
                  value={template.subject}
                  onChange={(e) => {
                    setTemplate({ ...template, subject: e.target.value });
                  }}
                />
              </div>
              <div className="form-group">
                {emailCategory.length ? (
                  <>
                    <label>Email Template</label>
                    <Select
                      size="large"
                      style={{
                        width: "100%",
                      }}
                      onChange={handleTemplateChange}
                      defaultValue={emailCategory
                        ?.filter((obj) => obj?.defaultEnabled)
                        ?.map((obj) => ({
                          value: obj?._id,
                          label: obj?.name,
                        }))}
                      options={emailCategory?.map((obj) => ({
                        value: obj?._id,
                        label: obj?.name,
                      }))}
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="form-group w-100">
                <input
                  type="file"
                  name="attachments"
                  id="attachment"
                  onChange={handleAttachFile}
                  className="radius-30 border w-100"
                  multiple
                  {...{
                    ...((fileSizeError || validFileError) && {
                      status: "error",
                    }),
                  }}
                  ref={inputRef}
                />
                <p className="m-0 text-secondary">
                  Accepted files images, texts, and pdf(s)
                </p>
                {!!files?.length &&
                  files?.map((file, ind) => {
                    const isValidFile = ATTACHMENT_TYPES.some((fileType) =>
                      file?.type?.includes(fileType),
                    );
                    return (
                      <div
                        className="d-flex"
                        style={{ justifyContent: "space-between" }}
                      >
                        <p
                          className={`m-0 ${!isValidFile ? "text-danger" : ""}`}
                        >
                          {file?.name}
                        </p>
                        <span
                          aria-hidden
                          onClick={() => handleRemoveFile(ind)}
                          className="text-danger"
                          style={{ cursor: "pointer" }}
                        >
                          x
                        </span>
                      </div>
                    );
                  })}
                {fileSizeError && (
                  <p className="text-danger m-0">
                    Can&apos;t upload more than 25MB
                  </p>
                )}
                {validFileError && (
                  <p className="text-danger m-0">Invalid file uploaded</p>
                )}
              </div>
              <div className="form-group">
                <div className="template-email">
                  <table
                    style={{
                      margin: "auto",
                      backgroundColor: "#ffffff",
                      width: "100%",
                      borderCollapse: "collapse",
                    }}
                    align="center"
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{ textAlign: template.logoPosition || "left" }}
                        >
                          {organizationDetails.logo && (
                            <img
                              src={createValidUrl(
                                organizationDetails.logo || "",
                              )}
                              className="logo-img"
                              alt="logo"
                            />
                          )}
                        </td>
                      </tr>
                      <div className="editor-box">
                        <Editor
                          editorState={editorState}
                          onEditorStateChange={setEditorState}
                          wrapperClassName="wrapper-class"
                          editorClassName="editor-class editor-class-body"
                          toolbarClassName="toolbar-class"
                          mention={{
                            trigger: "@",
                            separator: " ",
                            suggestions: mentions,
                          }}
                          toolbarHidden
                        />
                      </div>

                      <div>
                        <div className="editor-box">
                          <Editor
                            editorState={signature}
                            onEditorStateChange={setSignature}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class editor-class-body"
                            toolbarClassName="toolbar-class"
                            mention={{
                              trigger: "@",
                              separator: " ",
                              suggestions: mentions,
                            }}
                            toolbarHidden
                          />
                        </div>
                      </div>
                    </tbody>
                  </table>
                </div>
              </div>
            </Form>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          key="submit"
          type="primary"
          size="large"
          onClick={() => handleSendMail()}
          disabled={sendingEmail || generatingPdf}
        >
          {generatingPdf
            ? "Signing contract..."
            : sendingEmail
            ? "Sending..."
            : "Send"}
        </Button>
      </div>
      <ModalDailog isModalOpen={viewed} closeIcon={<></>}>
        <Row style={{ justifyContent: "center" }}>
          <Col>
            <div>
              {proposalDetails?.organization?.logo && (
                <img
                  src={createValidUrl(proposalDetails?.organization?.logo)}
                  alt="Company logo"
                  style={{ width: 200 }}
                />
              )}
            </div>
            <div>
              <p style={{ fontSize: 20, fontWeight: "bold" }}>
                Your Estimate From {proposalDetails?.organization?.companyName}
              </p>
            </div>
            <div>
              <Button
                type="primary"
                onClick={() => viewEstimate()}
                style={{ margin: "0 auto" }}
                disabled={callingView}
              >
                View Estimation
              </Button>
            </div>
          </Col>
        </Row>
      </ModalDailog>
    </>
  );
};

export default SendProposalToCustomer;
