/* eslint-disable no-unused-vars */
import { Button, Card, Col, Row } from "antd";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

import { createValidUrl } from "../../../utils/commonFuctions";
import { contactFormat } from "../../../utils/javascript";

const ProposalCertificationOfCompletion = ({
  settings,
  proposalDetails,
  generatingPdf,
}) => (
  <>
    <div className="contract-bg-section my-5">
      <div className="d-flex aligin-items-center gap-3 justify-content-between pe-4">
        <div
          className="abouts-btn"
          style={{
            backgroundColor: settings?.templateColor,
            color: settings?.templateTextColor,
            padding: "20px 60px",
            ...(generatingPdf && {
              fontSize: "35px",
              fontWeight: "bold",
            }),
          }}
        >
          Certification Of Completion
        </div>
      </div>
    </div>

    <div className="ad-contract" style={{ marginTop: 30 }}>
      <Row gutter={[24, 24]}>
        {/* Customer Info */}
        <Col xs={12}>
          <label style={{ fontSize: 18, fontWeight: 600, marginBottom: 20 }}>
            Customer Info
          </label>
          <p className="m-0" style={{ fontSize: 14 }}>
            Name: {proposalDetails?.estimateDetails?.signedByClient?.name}
          </p>
          <p className="m-0" style={{ fontSize: 14 }}>
            Phone Number:{" "}
            {contactFormat(
              proposalDetails?.estimateDetails?.signedByClient
                ?.customerContactNumber || "N/A",
            )}
          </p>
          <p className="m-0" style={{ fontSize: 14 }}>
            Email:{" "}
            {proposalDetails?.estimateDetails?.signedByClient?.customerEmail ||
              "N/A"}
          </p>
          <p className="m-0" style={{ fontSize: 14 }}>
            Project Address:{" "}
            {proposalDetails?.estimateDetails?.signedByClient?.projectAddress ||
              "N/A"}
          </p>
        </Col>
        {/* Contractor Info */}
        <Col xs={12}>
          <label style={{ fontSize: 18, fontWeight: 600, marginBottom: 20 }}>
            Contractor Info
          </label>
          <p className="m-0" style={{ fontSize: 14 }}>
            Phone Number:{" "}
            {contactFormat(
              proposalDetails?.estimateDetails?.signedByOrganization
                ?.masterAdminPhoneNumber ||
                proposalDetails?.estimateDetails?.signedByOrganization
                  ?.phoneNumber ||
                "N/A",
            )}
          </p>
          <p className="m-0" style={{ fontSize: 14 }}>
            Email:{" "}
            {proposalDetails?.estimateDetails?.signedByOrganization
              ?.masterAdminEmail ||
              proposalDetails?.estimateDetails?.signedByOrganization?.email ||
              "N/A"}
          </p>
          <p className="m-0" style={{ fontSize: 14 }}>
            Address:{" "}
            {proposalDetails?.estimateDetails?.signedByOrganization
              ?.projectAddress ||
              proposalDetails?.estimateDetails?.signedByOrganization
                ?.companyBillingAddress ||
              proposalDetails?.estimateDetails?.signedByOrganization?.address ||
              "N/A"}
          </p>
          <p className="m-0" style={{ fontSize: 14 }}>
            Envelop ID: {proposalDetails?.estimateDetails?._id || "N/A"}
          </p>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        {/* Signed Customer Info */}
        <Col xs={12}>
          <label style={{ fontSize: 18, fontWeight: 600, margin: "20px 0px" }}>
            Signed By Customer
          </label>
          <p className="m-0" style={{ fontSize: 14 }}>
            Signer Name:{" "}
            {proposalDetails?.estimateDetails?.signedByClient?.name}
          </p>
          <p className="m-0" style={{ fontSize: 14 }}>
            Sent:{" "}
            {proposalDetails?.estimateDetails?.signedByClient
              ?.sentToCustomerDate ? (
              <>
                {moment
                  .utc(
                    proposalDetails?.estimateDetails?.signedByClient
                      ?.sentToCustomerDate,
                  )
                  .local()
                  .format("MM-DD-YYYY hh:mm A")}{" "}
                {Intl.DateTimeFormat().resolvedOptions().timeZone}
              </>
            ) : (
              " N/A"
            )}
          </p>
          <p className="m-0" style={{ fontSize: 14 }}>
            Signed:{" "}
            {proposalDetails?.estimateDetails?.signedByClient?.date ? (
              <>
                {moment
                  .utc(proposalDetails?.estimateDetails?.signedByClient?.date)
                  .local()
                  .format("MM-DD-YYYY hh:mm A")}{" "}
                {Intl.DateTimeFormat().resolvedOptions().timeZone}
              </>
            ) : (
              " N/A"
            )}
          </p>
          <p className="m-0" style={{ fontSize: 14 }}>
            IP Address: {proposalDetails?.estimateDetails?.signedByClient?.ip}
          </p>
          <p className="m-0" style={{ fontSize: 14 }}>
            Security Level:{" "}
            {proposalDetails?.estimateDetails?.signedByClient
              ?.signedByEmployeeId
              ? "ZevBit, Account Authentication"
              : "Email, Account Authentication"}
          </p>
          <p className="m-0" style={{ fontSize: 14 }}>
            Signature Style:{" "}
            {proposalDetails?.estimateDetails?.signedByClient?.signatureStyle}
          </p>
        </Col>
        {/* Signed Contractor Info */}
        <Col xs={12}>
          <label style={{ fontSize: 18, fontWeight: 600, margin: "20px 0px" }}>
            Signed By Contractor
          </label>
          <p className="m-0" style={{ fontSize: 14 }}>
            Signer Name:{" "}
            {proposalDetails?.estimateDetails?.signedByOrganization?.name}
          </p>
          <p className="m-0" style={{ fontSize: 14 }}>
            Signed:{" "}
            {moment
              .utc(proposalDetails?.estimateDetails?.signedByOrganization?.date)
              .local()
              .format("MM-DD-YYYY hh:mm A")}{" "}
            {Intl.DateTimeFormat().resolvedOptions().timeZone}{" "}
          </p>
          <p className="m-0" style={{ fontSize: 14 }}>
            IP Address:{" "}
            {proposalDetails?.estimateDetails?.signedByOrganization?.ip}
          </p>
          <p className="m-0" style={{ fontSize: 14 }}>
            Security Level: ZevBit, Account Authentication
          </p>
          <p className="m-0" style={{ fontSize: 14 }}>
            Signature Adoption:{" "}
            {proposalDetails?.estimateDetails?.signedByOrganization
              ?.signatureStyle || "N/A"}
          </p>
        </Col>
      </Row>
    </div>
  </>
);

export default ProposalCertificationOfCompletion;
