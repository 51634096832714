/* eslint-disable no-unused-vars */
import { Button, Form, message } from "antd";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { updateProposalAboutUs } from "../../../../api/proposal";
import {
  MAX_SIZE_DESC,
  MAX_SIZE_TITLE,
} from "../../../../utils/constants/constants";
import { replaceWithoutTagValue } from "../../../../utils/suggestions";
import ProposalEditor from "./ProposalEditor";
import ProposalImageCropper from "./ProposalImageCropper";
import ProposalTitleEditor from "./ProposalTitleEditor";

const EditAboutUsForm = ({
  proposalDetails,
  handleCancel,
  setProposalDetails,
}) => {
  const [aboutUsPhoto, setAboutUsPhoto] = useState(null);
  const [aboutUsImageURL, setAboutUsImageURL] = useState("");
  const [errors, setErrors] = useState(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [updating, setUpdating] = useState(false);
  const organizationDetails = useSelector((state) => state?.user?.organization);
  const [titleEditorState, setTitleEditorState] = useState(
    EditorState.createEmpty(),
  );

  const handleSaveContent = async (isDefault = false) => {
    setUpdating(true);
    const formData = new FormData();
    formData.append(
      "title",
      replaceWithoutTagValue(
        draftToHtml(convertToRaw(titleEditorState.getCurrentContent())),
      ),
    );
    formData.append(
      "description",
      replaceWithoutTagValue(
        draftToHtml(convertToRaw(editorState.getCurrentContent())),
      ),
    );
    if (aboutUsPhoto) {
      formData.append("photo", aboutUsPhoto);
    }
    const response = await updateProposalAboutUs({
      estId: proposalDetails?.estimateId,
      data: formData,
      isDefault,
    });
    if (response?.remote === "success") {
      setProposalDetails((prev) => ({
        ...prev,
        ...response?.data?.data,
      }));
      setUpdating(false);
      handleCancel();
    } else {
      console.log(
        "Error while updating about proposal => ",
        response?.errors?.errors,
      );
      message.error(
        response?.errors?.errors?.error ||
          response?.errors?.errors?.message ||
          response?.errors?.errors,
      );
      setUpdating(false);
    }
  };

  useEffect(() => {
    if (proposalDetails?.aboutUs) {
      const templateTitle = htmlToDraft(proposalDetails?.aboutUs?.title || "");
      const titleContentState = ContentState.createFromBlockArray(
        templateTitle.contentBlocks,
      );
      const templateBody = htmlToDraft(
        proposalDetails?.aboutUs?.description || "",
      );
      const contentState = ContentState.createFromBlockArray(
        templateBody.contentBlocks,
      );
      setEditorState(EditorState.createWithContent(contentState));
      setTitleEditorState(EditorState.createWithContent(titleContentState));
    }
  }, [proposalDetails?.aboutUs]);

  useEffect(() => {
    if (editorState) {
      const desc = replaceWithoutTagValue(
        draftToHtml(convertToRaw(editorState.getCurrentContent())),
      );
      if (desc?.length > MAX_SIZE_DESC) {
        setErrors((prev) => ({
          ...prev,
          desc: `Description should be less than ${MAX_SIZE_DESC} chars`,
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          desc: "",
        }));
      }
    }
  }, [editorState]);

  useEffect(() => {
    if (titleEditorState) {
      const desc = replaceWithoutTagValue(
        draftToHtml(convertToRaw(titleEditorState.getCurrentContent())),
      ).replace(/<\/?[^>]*>/g, "");
      if (desc?.length > MAX_SIZE_TITLE) {
        setErrors((prev) => ({
          ...prev,
          title: `Title should be less than ${MAX_SIZE_TITLE} chars`,
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          title: "",
        }));
      }
    }
  }, [titleEditorState]);

  return (
    <Form layout="vertical" className="formstate">
      <Form.Item label="Header">
        <ProposalTitleEditor
          editorState={titleEditorState}
          setEditorState={setTitleEditorState}
        />
        <div className="d-flex justify-content-between w-100">
          <p className="m-0 text-danger">{errors?.title}</p>
          <p className="text-secondary">
            {
              replaceWithoutTagValue(
                draftToHtml(convertToRaw(titleEditorState.getCurrentContent())),
              ).replace(/<\/?[^>]*>/g, "")?.length
            }{" "}
            / {MAX_SIZE_TITLE}
          </p>
        </div>
      </Form.Item>
      <Form.Item label="About Us">
        <ProposalEditor
          editorState={editorState}
          setEditorState={setEditorState}
        />
        {errors?.desc && <p className="text-danger">{errors?.desc}</p>}
      </Form.Item>

      <Form.Item label="Photo">
        <ProposalImageCropper
          setImageURL={setAboutUsImageURL}
          imageURL={aboutUsImageURL}
          setPhoto={setAboutUsPhoto}
          photo={aboutUsPhoto}
          setErrors={setErrors}
          errors={errors}
        />
      </Form.Item>
      <Form.Item>
        <div className="d-flex align-items-center gap-2 justify-content-end">
          <Button className="clear-filter" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className="clear-filter"
            onClick={() => handleSaveContent()}
            disabled={
              updating || Object.values(errors || {}).filter(Boolean).length
            }
          >
            Save For This Estimate
          </Button>
          <Button
            className="apply-btn"
            type="primary"
            onClick={() => handleSaveContent(true)}
            disabled={
              updating || Object.values(errors || {}).filter(Boolean).length
            }
          >
            Save As Default
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
export default EditAboutUsForm;
