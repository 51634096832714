import { Card } from "antd";
import React from "react";
import { useSearchParams } from "react-router-dom";

import { createValidUrl } from "../../../utils/commonFuctions";
import { replaceMentions } from "../../../utils/suggestions";
// import { useSelector } from "react-redux";

// import { createValidUrl } from "../../../utils/commonFuctions";

const ProposalNextStep = ({
  proposalDetails,
  settings,
  generatingPdf,
  userDetails,
}) => {
  const [params] = useSearchParams();
  const token = params.get("token");
  const RenderContent = () => (
    <>
      <div className="contract-bg-section mt-5">
        <div className="d-flex align-items-center gap-3 justify-content-between pe-4">
          <div
            className="abouts-btn"
            style={{
              backgroundColor: settings?.templateColor,
              color: settings?.templateTextColor,
              padding: "20px 60px",
              ...(generatingPdf && {
                fontSize: "35px",
                fontWeight: "bold",
              }),
            }}
          >
            Next Steps
          </div>
          {!settings?.paymentTerms &&
            (userDetails?.organization?.logo ||
              proposalDetails?.organizationDetails?.logo) && (
              <div className="ms-auto">
                <img
                  src={createValidUrl(
                    token
                      ? proposalDetails?.organizationDetails?.logo
                      : userDetails?.organization?.logo,
                  )}
                  alt="next-step"
                  className="company-logo"
                />
              </div>
            )}
        </div>
      </div>
      <div className="mt-5 ad-contract">
        <div
          dangerouslySetInnerHTML={{
            __html: replaceMentions(
              proposalDetails?.nextSteps?.description,
              proposalDetails?.customerDetails,
              token
                ? proposalDetails?.organizationDetails
                : userDetails?.organization,
              token ? proposalDetails?.userDetails : userDetails,
            ),
          }}
        />
      </div>
    </>
  );
  return settings?.paymentTerms ? (
    <RenderContent />
  ) : (
    <Card
      bordered={false}
      style={{
        borderRadius: "12px",
        boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
      }}
      className="mb-3 proposal-card-h-fix"
    >
      <RenderContent />
    </Card>
  );
};
export default ProposalNextStep;
