/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
import "./content-style.css";

import { ArrowLeftOutlined, DownOutlined } from "@ant-design/icons";
import { drawDOM, pdf } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import { Button, Card, Dropdown, message, Modal } from "antd";
import copy from "copy-to-clipboard";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { getLeadById } from "../../../api/admin";
import {
  copyProposalShareableLink,
  sendShareableLink,
} from "../../../api/proposal";
import { SmallLoader } from "../../../common/components/small-loader";
import { setCustomerLeadInfo } from "../../../redux/lead/lead.action";
import { createValidUrl } from "../../../utils/commonFuctions";
import { downloadCont } from "../../../utils/svg.file";
import {
  BackBtn,
  InvoiceEye,
  invoiceSendIcon,
  sendLink,
} from "../../invoicing/svg.icons";
import SendProposalToCustomer from "./forms/SendProposalToCustomer";
import {
  downloadBase64File,
  exportElement,
  getBase64OfHTMLDivComponentAsPDF,
} from "./helper";
// import { downloadBase64File, getBase64OfHTMLDivComponentAsPDF } from "./helper";

const ProposalHeader = ({
  getProposalSettings,
  selectedProposal,
  selectedProposalSettings,
  contractorSigningContract,
  generatingPdf,
  setGeneratingPdf,
  contentRef,
  proposalDetails,
  contractorSignature,
  // setContractorSigningContract,
  // handleDownloadPdf,
  checkingSignature,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sendEmail, setSendEmail] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [files, setFiles] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [validFileError, setValidFileError] = useState(false);
  // const [estimationDetails, setEstimationDetails] = useState({});
  const customerDetails = useSelector((state) => state.lead.leadInfo);
  const [copingLink, setCopingLink] = useState(false);

  const [params] = useSearchParams();
  const customerId = params.get("customerId");
  const token = params.get("token");

  const items = [
    {
      label: "Send Via Email",
      key: "1",
      icon: invoiceSendIcon,
      onClick: () => setSendEmail(true),
    },
    {
      label: "Copy Shareable Link",
      key: "2",
      icon: sendLink,
      onClick: async () => {
        setCopingLink(true);
        const response = await copyProposalShareableLink({
          proposalId: selectedProposalSettings?._id,
          estId: selectedProposalSettings?.estimateId,
        });
        if (response.remote === "success") {
          message.success("Shareable Link copied to clipboard");
          copy(response?.data?.data);
          getProposalSettings();
          setCopingLink(false);
        } else {
          setCopingLink(false);
        }
      },
    },
    {
      label: "Send Link To My Email",
      key: "3",
      icon: (
        <span>
          <InvoiceEye />
        </span>
      ),
      onClick: async () => {
        const response = await sendShareableLink({
          proposalId: selectedProposalSettings?._id,
          estId: selectedProposalSettings?.estimateId,
        });
        if (response.remote === "success") {
          message.success("Shareable Link has been sent to your email");
          getProposalSettings(selectedProposal);
        } else {
          console.log(
            "Error while sending shareable link => ",
            response?.errors?.errors,
          );
          message.error(response?.errors?.errors?.error);
        }
      },
    },
  ];

  const handleBack = () => {
    navigate(-1);
  };

  const handleDownloadAttachment = async (attachmentURL) => {
    const response = await fetch(attachmentURL);
    const blob = await response.blob();

    const fileUrl = window.URL.createObjectURL(new Blob([blob]));
    const a = document.createElement("a");
    a.href = fileUrl;
    a.download = `${
      selectedProposalSettings?.customerDetails?.fullName || ""
    }-${
      selectedProposalSettings?.estimateDetails.estimationNumber
    }-attachment.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(fileUrl);
  };

  const handleDownloadPdf = async () => {
    setGeneratingPdf(true);
    exportElement(
      contentRef.current,
      {
        paperSize: "A4",
        margin: "0cm",
        scale: 0.73,
      },
      `${selectedProposalSettings?.customerDetails?.fullName || ""}-${
        selectedProposalSettings?.estimateDetails.estimationNumber
      }.pdf`,
    );
    if (
      selectedProposalSettings?.estimateDetails?.attachment?.includes(".pdf")
    ) {
      handleDownloadAttachment(
        createValidUrl(selectedProposalSettings?.estimateDetails?.attachment),
      );
    }
    setTimeout(() => {
      setGeneratingPdf(false);
    }, 1000);
  };

  useEffect(() => {
    if (!token) {
      const getCustomerDetails = async (customerId) => {
        const response = await getLeadById(null, customerId);
        if (response.remote === "success") {
          dispatch(setCustomerLeadInfo(response.data.leadDetails));
        }
      };
      if (customerDetails?._id !== customerId) {
        getCustomerDetails(customerId);
      }
    }
  }, [customerId]);
  return (
    <>
      <Card
        className="proposal_mobile_header_card"
        bordered={false}
        bodyStyle={{
          padding: "12px",
          zIndex: 999,
        }}
        {...{
          ...(token && {
            style: {
              margin: "0px auto",
              borderRadius: "0px",
            },
          }),
        }}
      >
        <div
          className={`d-flex align-items-center ${
            token ? "justify-content-end" : "justify-content-between"
          } gap-2`}
        >
          {!token && (
            <div className="d-flex align-items-center">
              <Button
                className="clear-filter proposal_btn_clear"
                onClick={() => handleBack()}
              >
                {BackBtn}
                <span className="ms-2">Back</span>
              </Button>
            </div>
          )}
          <div className="d-flex align-items-center gap-2 gap-lg-3">
            <Button
              className="clear-filter clear-p"
              onClick={() => handleDownloadPdf()}
              disabled={generatingPdf}
              loading={generatingPdf}
            >
              {downloadCont}
              <span className="ms-2">Download</span>
            </Button>
            {!token && (
              <Dropdown
                overlayStyle={{ zIndex: 9999 }}
                menu={{
                  items,
                }}
                overlayClassName="send-list-view"
                trigger={["click"]}
                placement="bottomRight"
                disabled={copingLink}
                // disabled={
                //   generatingPdf ||
                //   checkingSignature ||
                //   (selectedProposalSettings?.settings?.signature
                //     ? !(
                //         contractorSignature ||
                //         (selectedProposalSettings?.estimateDetails
                //           ?.signedByOrganization?._id &&
                //           selectedProposalSettings?.estimateDetails
                //             ?.signedByOrganization?.signature)
                //       )
                //     : false)
                // }
              >
                <Button
                  style={{ fontSize: "14px", height: "40px" }}
                  type="primary"
                  size="large"
                  className="apply-btn"
                  loading={copingLink}
                  disabled={copingLink}
                >
                  {invoiceSendIcon}
                  <span className="ms-2">
                    Send <span className="mobile-cus">Document</span>
                  </span>
                  <DownOutlined style={{ fontSize: "18px" }} />
                </Button>
              </Dropdown>
            )}
          </div>
        </div>
      </Card>
      <Modal
        open={sendEmail}
        onCancel={() => {
          setFiles(null);
          setFileSizeError(false);
          setValidFileError(false);
          if (!sendingEmail) setSendEmail(false);
        }}
        width={800}
        className="email-preview-modal"
        title={[
          <div className="d-flex align-items-center">
            <span
              className="me-2 lineheight-12 cursor-pointer"
              // onClick={onClose}
            >
              <ArrowLeftOutlined />
            </span>
            Preview
          </div>,
        ]}
        footer={null}
      >
        <SendProposalToCustomer
          sendingEmail={sendingEmail}
          setSendingEmail={setSendingEmail}
          templateName="Estimate"
          onClose={() => {
            setFiles(null);
            setFileSizeError(false);
            setValidFileError(false);
            if (!sendingEmail) setSendEmail(false);
          }}
          estimationId={selectedProposalSettings?.estimateId}
          generatingPdf={generatingPdf}
          files={files}
          setFiles={setFiles}
          fileSizeError={fileSizeError}
          setFileSizeError={setFileSizeError}
          validFileError={validFileError}
          setValidFileError={setValidFileError}
          proposalId={selectedProposalSettings?._id}
          getProposalSettings={getProposalSettings}
          proposalDetails={proposalDetails}
        />
      </Modal>
    </>
  );
};

export default ProposalHeader;
