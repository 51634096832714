/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
import { CloudUploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Upload } from "antd";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { updateProposalPastProjects } from "../../../../api/proposal";
import { createValidUrl } from "../../../../utils/commonFuctions";
import {
  MAX_SIZE_DESC,
  MAX_SIZE_TITLE,
} from "../../../../utils/constants/constants";
import { replaceWithoutTagValue } from "../../../../utils/suggestions";
import {
  Delete,
  Featuredicon,
  ProposalDeleteIcon,
} from "../../../../utils/svg.file";
import ProposalEditor from "./ProposalEditor";
import ProposalTitleEditor from "./ProposalTitleEditor";
import ProposalUploader from "./ProposalUploader";
// import ProposalImageCropper from "./ProposalImageCropper";

const { Dragger } = Upload;

const EditPastProjectForm = ({
  proposalDetails,
  handleCancel,
  setProposalDetails,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [titleEditorState, setTitleEditorState] = useState(
    EditorState.createEmpty(),
  );
  // const [title, setTitle] = useState("");
  const [errors, setErrors] = useState(null);
  const [pastProjectImages, setPastProjectImages] = useState([]);
  const [removedIndex, setRemovedIndex] = useState();
  const [uploadedList, setUploadedList] = useState([]);
  const [updating, setUpdating] = useState(false);
  const organization = useSelector((state) => state?.user?.organization);

  const saveData = async (formData, isDefault) => {
    setUpdating(true);
    const response = await updateProposalPastProjects({
      estId: proposalDetails?.estimateId,
      data: formData,
      isDefault,
    });
    if (response?.remote === "success") {
      setProposalDetails((prev) => ({
        ...prev,
        ...response?.data?.data,
      }));
      setUpdating(false);
      handleCancel();
    } else {
      console.log(
        "Error while updating past projects => ",
        response?.errors?.errors,
      );
      message.error(
        response?.errors?.errors?.error ||
          response?.errors?.errors?.message ||
          response?.errors?.errors,
      );
      setUpdating(false);
    }
  };

  const handleSaveContent = (isDefault = false) => {
    setUpdating(true);
    const formData = new FormData();
    formData.append(
      "title",
      replaceWithoutTagValue(
        draftToHtml(convertToRaw(titleEditorState.getCurrentContent())),
      ),
    );
    formData.append(
      "description",
      replaceWithoutTagValue(
        draftToHtml(convertToRaw(editorState.getCurrentContent())),
      ),
    );
    if (pastProjectImages?.length) {
      if (pastProjectImages?.length === 4) {
        pastProjectImages?.forEach((file) => {
          const hasLink =
            typeof file === "string"
              ? file?.includes("https://") || file?.includes("http://")
                ? file?.split(".net/")[1]?.split?.("?Policy")[0]
                : file
              : file;
          console.log("hasLink => ", hasLink);
          formData.append("photos", hasLink);
        });
        saveData(formData, isDefault);
      } else {
        setErrors({ photos: "Please upload 4 images" });
      }
    } else {
      saveData(formData, isDefault);
    }
    setUpdating(false);
  };

  useEffect(() => {
    if (proposalDetails?.pastProject) {
      const templateBody = htmlToDraft(
        proposalDetails?.pastProject?.description || "",
      );
      const contentState = ContentState.createFromBlockArray(
        templateBody.contentBlocks,
      );
      const templateTitle = htmlToDraft(
        proposalDetails?.pastProject?.title || "",
      );
      const contentTitleState = ContentState.createFromBlockArray(
        templateTitle.contentBlocks,
      );
      setEditorState(EditorState.createWithContent(contentState));
      setTitleEditorState(EditorState.createWithContent(contentTitleState));
      if (proposalDetails?.pastProject?.photos?.length) {
        const files = [];
        proposalDetails?.pastProject?.photos?.forEach(async (url) => {
          files.push(createValidUrl(url));
        });
        setPastProjectImages(files);
      }
    }
  }, [proposalDetails?.pastProject]);

  const customRequest = ({ onSuccess, file }) => {
    let hasFiles = [...pastProjectImages];
    if (hasFiles?.length < 4) {
      if (removedIndex !== undefined) {
        hasFiles?.splice(removedIndex, 0, file);
      } else {
        hasFiles.unshift(file);
      }
    } else {
      if (uploadedList?.length <= 4) {
        hasFiles?.splice(
          hasFiles?.length - uploadedList?.length,
          uploadedList?.length,
          file,
        );
      } else {
        hasFiles = uploadedList?.slice(0, 4);
      }
    }
    setPastProjectImages(hasFiles);
    onSuccess("ok");
  };
  const handleRemoveImage = (index) => {
    const cloned = [...pastProjectImages];
    cloned.splice(index, 1);
    if (~index) setRemovedIndex(index);
    setPastProjectImages(cloned);
  };
  useEffect(() => {
    if (pastProjectImages?.length) {
      if (pastProjectImages?.length === 4) {
        setErrors({ photos: "" });
      }
    }
  }, [pastProjectImages]);

  useEffect(() => {
    if (editorState) {
      const desc = replaceWithoutTagValue(
        draftToHtml(convertToRaw(editorState.getCurrentContent())),
      );
      if (desc?.length > MAX_SIZE_DESC) {
        setErrors((prev) => ({
          ...prev,
          desc: `Description should be less than ${MAX_SIZE_DESC} chars`,
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          desc: "",
        }));
      }
    }
  }, [editorState]);

  useEffect(() => {
    if (titleEditorState) {
      const desc = replaceWithoutTagValue(
        draftToHtml(convertToRaw(titleEditorState.getCurrentContent())),
      );
      if (desc?.length > MAX_SIZE_TITLE) {
        setErrors((prev) => ({
          ...prev,
          title: `Title should be less than ${MAX_SIZE_TITLE} chars`,
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          title: "",
        }));
      }
    }
  }, [titleEditorState]);

  return (
    <Form layout="vertical" className="formstate">
      <Form.Item label="Header">
        {/* <Input defaultValue="Thank you for considering Outdoor Excellence Landscaping!" /> */}
        {/* <Input
          placeholder="Header for past project"
          value={title}
          onChange={(e) => handleTitleChange(e?.target?.value)}
          defaultValue={proposalDetails?.pastProject?.title}
        /> */}
        <ProposalTitleEditor
          editorState={titleEditorState}
          setEditorState={setTitleEditorState}
        />
        {errors?.title && <p className="m-0 text-danger">{errors?.title}</p>}
      </Form.Item>
      <Form.Item label="Text">
        <ProposalEditor
          editorState={editorState}
          setEditorState={setEditorState}
        />
      </Form.Item>

      <Form.Item label="Photos (Select 4)">
        {/* Uploader with max count 4 */}
        <Dragger
          name="past-projects"
          maxCount={4}
          customRequest={(option) => customRequest(option)}
          beforeUpload={(_, fileList) => {
            setUploadedList(fileList);
          }}
          showUploadList={false}
          accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
          multiple
          defaultFileList={pastProjectImages}
          fileList={pastProjectImages}
        >
          <p className="ant-upload-drag-icon">
            <CloudUploadOutlined />
          </p>
          <p style={{ fontSize: 16 }} className="ant-upload-text">
            <span className="ant-uloadf">Click to upload</span>{" "}
            <span style={{ color: "#667085" }}>or</span>
          </p>
          <span style={{ color: "#667085" }}>Drag a past project images</span>
        </Dragger>
        {errors?.photos && <p className="m-0 text-danger">{errors?.photos}</p>}
      </Form.Item>
      {!!pastProjectImages?.length && (
        <div className="d-flex gap-3 mb-2 flex-wrap image-view">
          {pastProjectImages?.map((file, ind) => (
            <div key={ind} style={{ position: "relative", borderRadius: 8 }}>
              <img
                src={
                  typeof file === "object"
                    ? URL.createObjectURL(file)
                    : file ||
                      "https://images.unsplash.com/photo-1512917774080-9991f1c4c750"
                }
                alt={`past-project-${ind}`}
                width={180}
                height={122}
                style={{ borderRadius: 8, minWidth: 180 }}
              />
              <span
                onClick={() => handleRemoveImage(ind)}
                style={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  top: "0%",
                  width: "100%",
                  height: "100%",
                  color: "#DC3543",
                  cursor: "pointer",
                }}
              >
                <ProposalDeleteIcon />
              </span>
            </div>
          ))}
        </div>
      )}
      <Form.Item>
        <div className="d-flex align-items-center gap-2 justify-content-end flex-column flex-md-row">
          <Button className="clear-filter" onClick={() => handleCancel()}>
            Cancel
          </Button>
          <Button
            className="clear-filter"
            onClick={() => handleSaveContent()}
            disabled={
              updating || Object.values(errors || {}).filter(Boolean).length
            }
            loading={updating}
          >
            Save For This Estimate
          </Button>
          <Button
            className="apply-btn mobile-100"
            type="primary"
            onClick={() => handleSaveContent(true)}
            disabled={
              updating || Object.values(errors || {}).filter(Boolean).length
            }
            loading={updating}
          >
            Save As Default
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
export default EditPastProjectForm;
