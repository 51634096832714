import { Button, Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";

import { updateProposalProposedSchedule } from "../../../../api/proposal";
import { MAX_SIZE_TITLE } from "../../../../utils/constants/constants";

const EditProposedScheduleForm = ({
  handleCancel,
  proposalDetails,
  setProposalDetails,
}) => {
  const [details, setDetails] = useState({});
  const [updating, setUpdating] = useState(false);
  const [errors, setErrors] = useState();
  const handleChange = (e) => {
    const { name, value } = e?.target;
    setDetails((prev) => ({ ...prev, [name]: value }));
    if (value?.length > MAX_SIZE_TITLE) {
      setErrors((prev) => ({
        ...prev,
        [name]: `Length should be less than ${MAX_SIZE_TITLE} chars`,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        [name]: ``,
      }));
    }
  };
  const handleSubmit = async (isDefault = false) => {
    setUpdating(true);
    const formData = new FormData();
    Object.entries(details).forEach(([k, v]) => formData.append(k, v));
    const response = await updateProposalProposedSchedule({
      estId: proposalDetails?.estimateId,
      data: formData,
      isDefault,
    });
    if (response?.remote === "success") {
      setProposalDetails((prev) => ({
        ...prev,
        ...response?.data?.data,
      }));
      setUpdating(false);
      handleCancel();
    } else {
      console.log(
        "Error while updating proposed schedule => ",
        response?.errors?.errors,
      );
      message.error(
        response?.errors?.errors?.error ||
          response?.errors?.errors?.message ||
          response?.errors?.errors,
      );
      setUpdating(false);
    }
  };
  useEffect(() => {
    if (proposalDetails?.proposalSchedule) {
      Object.entries(proposalDetails?.proposalSchedule)?.forEach(([k, v]) =>
        setDetails((prev) => ({ ...prev, [k]: v })),
      );
    }
  }, [proposalDetails?.proposalSchedule]);

  return (
    <Form layout="vertical" className="formstate">
      <Form.Item label="Proposed Start Date">
        <Input.TextArea
          defaultValue={
            proposalDetails?.proposalSchedule?.startDateDescription || ""
          }
          name="startDateDescription"
          value={details?.startDateDescription}
          onChange={(e) => handleChange(e)}
        />
        {errors?.startDateDescription && (
          <p className="text-danger">{errors?.startDateDescription}</p>
        )}
      </Form.Item>
      <Form.Item label="Estimated Completion Time">
        <Input.TextArea
          defaultValue={
            proposalDetails?.proposalSchedule?.completionTimeDescription || ""
          }
          name="completionTimeDescription"
          value={details?.completionTimeDescription}
          onChange={(e) => handleChange(e)}
        />
        {errors?.completionTimeDescription && (
          <p className="text-danger">{errors?.completionTimeDescription}</p>
        )}
      </Form.Item>
      <Form.Item label="Additional Notes">
        <Input.TextArea
          defaultValue={
            proposalDetails?.proposalSchedule?.additionalNotes || ""
          }
          name="additionalNotes"
          value={details?.additionalNotes}
          onChange={(e) => handleChange(e)}
        />
        {errors?.additionalNotes && (
          <p className="text-danger">{errors?.additionalNotes}</p>
        )}
      </Form.Item>

      <Form.Item>
        <div className="d-flex align-items-center gap-2 justify-content-end">
          <Button className="clear-filter" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className="clear-filter"
            onClick={() => handleSubmit()}
            disabled={updating}
          >
            Save For This Estimate
          </Button>
          <Button
            className="apply-btn"
            type="primary"
            onClick={() => handleSubmit(true)}
            disabled={updating}
          >
            Save As Default
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default EditProposedScheduleForm;
