import { Button, Form, message } from "antd";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { useEffect, useState } from "react";

import { updateProposalNextSteps } from "../../../../api/proposal";
import { MAX_SIZE_DESC } from "../../../../utils/constants/constants";
import { replaceWithoutTagValue } from "../../../../utils/suggestions";
import ProposalEditor from "./ProposalEditor";

const EditNextStepsForm = ({
  proposalDetails,
  handleCancel,
  setProposalDetails,
}) => {
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty(),
  );
  const [error, setError] = useState("");
  const [updating, setUpdating] = useState(false);
  const handleSubmit = async (isDefault = false) => {
    setUpdating(true);
    const formData = new FormData();
    formData.append(
      "description",
      replaceWithoutTagValue(
        draftToHtml(convertToRaw(editorState.getCurrentContent())),
      ),
    );
    const response = await updateProposalNextSteps({
      estId: proposalDetails?.estimateId,
      data: formData,
      isDefault,
    });

    if (response?.remote === "success") {
      setProposalDetails((prev) => ({
        ...prev,
        ...response?.data?.data,
      }));
      setUpdating(false);
      handleCancel();
    } else {
      console.log(
        "Error while updating next steps => ",
        response?.errors?.errors,
      );
      message.error(
        response?.errors?.errors?.error ||
          response?.errors?.errors?.message ||
          response?.errors?.errors,
      );
      setUpdating(false);
    }
  };

  useEffect(() => {
    if (proposalDetails?.nextSteps) {
      const templateBody = htmlToDraft(
        proposalDetails?.nextSteps?.description || "",
      );
      const contentState = ContentState.createFromBlockArray(
        templateBody.contentBlocks,
      );
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [proposalDetails?.nextSteps]);

  useEffect(() => {
    if (editorState) {
      const desc = replaceWithoutTagValue(
        draftToHtml(convertToRaw(editorState.getCurrentContent())),
      );
      if (desc?.length > MAX_SIZE_DESC) {
        setError(`Description should be less than ${MAX_SIZE_DESC} chars`);
      } else {
        setError("");
      }
    }
  }, [editorState]);

  return (
    <Form layout="vertical" className="formstate">
      <Form.Item label="Next Steps">
        <ProposalEditor
          editorState={editorState}
          setEditorState={setEditorState}
        />
        {error && <p className="text-danger">{error}</p>}
      </Form.Item>

      <Form.Item>
        <div className="d-flex align-items-center gap-2 justify-content-end">
          <Button className="clear-filter" onClick={() => handleCancel()}>
            Cancel
          </Button>
          <Button
            className="clear-filter"
            disabled={updating || error}
            onClick={() => handleSubmit()}
          >
            Save For This Estimate
          </Button>
          <Button
            className="apply-btn"
            type="primary"
            disabled={updating || error}
            onClick={() => handleSubmit(true)}
          >
            Save As Default
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
export default EditNextStepsForm;
