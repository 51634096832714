import React from "react";
import { Editor } from "react-draft-wysiwyg";
import { useSelector } from "react-redux";

import { modifiedTagsValue } from "../../../../utils/constants/constants";
import { mentions } from "../../../../utils/suggestions";

const ProposalEditor = ({ editorState, setEditorState }) => {
  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState); // Update the state with the new editor state
  };
  const { tags } = useSelector((state) => state?.invoice);
  return (
    <Editor
      editorState={editorState} // Pass the current editor state to the component
      onEditorStateChange={onEditorStateChange} // Handle state change
      wrapperClassName="wrapper-class"
      editorClassName="editor-class"
      toolbarClassName="toolbar-class"
      toolbar={{
        options: ["inline"],
        inline: {
          options: ["bold"],
        },
      }}
      mention={{
        trigger: "@",
        separator: " ",
        suggestions: modifiedTagsValue(tags, mentions),
      }}
    />
  );
};

export default ProposalEditor;
