/* eslint-disable no-unused-vars */
import { Button, Form, Input } from "antd";
import { upperCase } from "lodash";
import React, { useState } from "react";

import { updateProposalCoverPage } from "../../../../api/proposal";
import { createValidUrl } from "../../../../utils/commonFuctions";
import { Featuredicon } from "../../../../utils/svg.file";
import UploadFile from "../../../setting/companysettings/card/uploadFile.component";
import ProposalImageCropper from "./ProposalImageCropper";

const EditCoverPageForm = ({
  proposalDetails,
  setProposalDetails,
  handleCancel,
}) => {
  const [coverPageTitle, setCoverPageTitle] = useState(
    proposalDetails?.coverPage?.title,
  );
  const [coverPagePhoto, setCoverPagePhoto] = useState(null);
  const [coverPageImageURL, setCoverPageImageURL] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const handleTitleChange = (e) => {
    if (e?.target?.value?.length > 15) {
      setErrors((prev) => ({
        ...prev,
        title: "Length must be less than or equal to 15 characters long",
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        title: "",
      }));
    }
    const value = e?.target?.value;
    setCoverPageTitle(value);
  };
  const handleEditCoverPage = async (isDefault = false) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("title", coverPageTitle?.toUpperCase());
    if (coverPagePhoto) {
      formData.append("photo", coverPagePhoto);
    }
    const response = await updateProposalCoverPage({
      estId: proposalDetails?.estimateId,
      data: formData,
      isDefault,
    });
    if (response.remote === "success") {
      setProposalDetails((prev) => ({
        ...prev,
        ...response?.data?.data,
      }));
      setIsLoading(false);
      handleCancel();
    }
    setIsLoading(false);
  };
  return (
    <Form layout="vertical" className="formstate">
      <Form.Item label="Page Name">
        <Input
          placeholder="Cover Page Title"
          value={coverPageTitle}
          onChange={(e) => handleTitleChange(e)}
          style={{ textTransform: "uppercase", height: "44px" }}
        />
        {errors?.title && <p className="m-0 text-danger">{errors?.title}</p>}
      </Form.Item>
      <Form.Item label="Cover Photo">
        {/* <div className="upload-ab-contract mb-5"> */}
        <ProposalImageCropper
          setImageURL={setCoverPageImageURL}
          imageURL={coverPageImageURL}
          setPhoto={setCoverPagePhoto}
          photo={coverPagePhoto}
          setErrors={setErrors}
          errors={errors}
        />
        {/* </div> */}
      </Form.Item>
      <Form.Item style={{ margin: 0 }}>
        <div className="d-flex align-items-center w-100 gap-2 justify-content-between">
          <Button
            className="cancel-btn clear-filter w-100"
            onClick={() => {
              setErrors(null);
              handleCancel();
            }}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            className="cancel-btn clear-filter w-100"
            onClick={() => handleEditCoverPage()}
            disabled={
              isLoading ||
              Object.values(errors || {}).filter(Boolean).length ||
              (!coverPagePhoto && !coverPageTitle)
            }
          >
            Save For This Estimate
          </Button>
          <Button
            className="apply-btn w-100"
            type="primary"
            onClick={() => handleEditCoverPage(true)}
            disabled={
              isLoading ||
              Object.values(errors || {}).filter(Boolean).length ||
              (!coverPagePhoto && !coverPageTitle)
            }
          >
            Save As Default
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
export default EditCoverPageForm;
