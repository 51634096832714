import { EditOutlined } from "@ant-design/icons";
import { Button, Form, Image } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import React, { useState } from "react";

import { createValidUrl } from "../../../../utils/commonFuctions";

const EditLineItemPhoto = ({
  customRequest,
  setShowCropper,
  handleSaveContent,
  imageUrl,
  validateImage,
  updating,
  handleCancel,
  errors,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <Form>
      <Form.Item noStyle>
        <Dragger
          name="line-photo"
          maxCount={1}
          customRequest={customRequest}
          showUploadList={false}
          accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
          onRemove={() => setShowCropper(false)}
          onStart={() => {
            handleCancel();
            setShowCropper(true);
          }}
          className="mb-3"
          beforeUpload={validateImage}
          style={{
            border: "1px solid #DADADA",
            borderRadius: 8,
            width: "100%",
            marginBottom: "25px",
          }}
        >
          <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <Image
              src={createValidUrl(imageUrl)}
              alt="lead-1-img"
              style={{
                width: "100%" /* , height: "40px" */,
              }}
              preview={false}
              wrapperStyle={{ width: "100%" }}
            />
            {hover && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#484D59BF",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "24px",
                  borderRadius: 8,
                }}
              >
                <EditOutlined />
              </div>
            )}
          </div>
        </Dragger>
      </Form.Item>
      <Form.Item className="m-0" noStyle>
        <div className="d-flex align-items-center gap-2 justify-content-end">
          <Button className="clear-filter" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className="clear-filter"
            onClick={() => handleSaveContent()}
            disabled={
              updating || Object.values(errors || {}).filter(Boolean).length
            }
          >
            Save For This Estimate
          </Button>
          <Button
            className="apply-btn"
            type="primary"
            onClick={() => handleSaveContent(true)}
            disabled={
              updating || Object.values(errors || {}).filter(Boolean).length
            }
          >
            Save As Default
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default EditLineItemPhoto;
