import { EditOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { createValidUrl } from "../../../utils/commonFuctions";
import ProposalCertificationOfCompletion from "./ProposalCertificationOfCompletion";

const ProposalSignature = ({
  settings,
  proposalDetails,
  customerSignature,
  generatingPdf,
  contractorSignature,
  // compareUrlWithBase64,
  setESignatureOpenFor,
  setShowCustomerSignatureWarning,
  SIGNATURE_FOR,
  editable,
  setContractorSignature,
  organizationInfo,
}) => {
  const [searchParams] = useSearchParams();
  const userDetails = useSelector((state) => state?.user);
  const token = searchParams.get("token");
  useEffect(() => {
    if (
      proposalDetails?.estimateDetails?.signedByOrganization?._id &&
      !contractorSignature
    ) {
      setContractorSignature(
        createValidUrl(
          proposalDetails?.estimateDetails?.signedByOrganization?.signature,
        ),
      );
    }
  }, [proposalDetails]);

  return (
    <Card
      bordered={false}
      style={{
        borderRadius: "12px",
        boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
      }}
      className="mb-3 proposal-card-h-fix"
    >
      <div className="contract-bg-section">
        <div className="d-flex align-items-center gap-3 justify-content-between pe-4">
          <div
            className="abouts-btn"
            style={{
              backgroundColor: settings?.templateColor,
              color: settings?.templateTextColor,
              padding: "20px 60px",
              ...(generatingPdf && {
                fontSize: "35px",
                fontWeight: "bold",
              }),
            }}
          >
            Signature
          </div>
          {(userDetails?.organization?.logo ||
            proposalDetails?.organizationDetails?.logo) && (
            <div className="ms-auto">
              <img
                src={createValidUrl(
                  token
                    ? proposalDetails?.organizationDetails?.logo
                    : userDetails?.organization?.logo,
                )}
                alt="signature"
                className="company-logo"
              />
            </div>
          )}
        </div>
      </div>

      <div className="mt-5 ad-contract">
        <Row gutter={[24, 24]}>
          <Col xs={12}>
            <div className="border-bottom-1 pb-2 customer_section">
              <label style={{ marginBottom: "5px" }}>Customer Signature</label>
              <div id="div_2">
                {customerSignature ||
                proposalDetails?.estimateDetails?.signedByClient?.signature ? (
                  <img
                    src={
                      customerSignature ||
                      createValidUrl(
                        proposalDetails?.estimateDetails?.signedByClient
                          ?.signature,
                      )
                    }
                    alt="CustomerSignature"
                    style={{ height: 50 }}
                  />
                ) : generatingPdf ? (
                  <div style={{ height: 50 }} />
                ) : (
                  <Button
                    type="primary"
                    className="add-btn proposal_btn"
                    size="large"
                    onClick={async () => {
                      const isSignable =
                        (proposalDetails?.estimateDetails?.signedByOrganization
                          ?._id &&
                          proposalDetails?.estimateDetails?.signedByOrganization
                            ?.signature) ||
                        !!contractorSignature;
                      if (isSignable) {
                        setESignatureOpenFor(SIGNATURE_FOR.CUSTOMER);
                      } else {
                        setShowCustomerSignatureWarning(true);
                      }
                    }}
                  >
                    Sign Estimate
                  </Button>
                )}
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <div className="border-bottom-1 pb-2 customer_section">
              <label style={{ display: "flex", marginBottom: "15px" }}>
                Contractor Signature
                {!generatingPdf &&
                  !token &&
                  ((proposalDetails?.estimateDetails?.signedByOrganization
                    ?._id &&
                    proposalDetails?.estimateDetails?.signedByOrganization
                      ?.signature) ||
                    contractorSignature) &&
                  !proposalDetails?.estimateDetails?.signedByClient?._id &&
                  !proposalDetails?.estimateDetails?.signedByClient
                    ?.signature && (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setESignatureOpenFor(SIGNATURE_FOR.CONTRACTOR)
                      }
                    >
                      <EditOutlined />
                    </div>
                  )}
              </label>
              <div id="div_2">
                {contractorSignature ||
                proposalDetails?.estimateDetails?.signedByOrganization
                  ?.signature ? (
                  <img
                    src={
                      contractorSignature ||
                      createValidUrl(
                        proposalDetails?.estimateDetails?.signedByOrganization
                          ?.signature,
                      )
                    }
                    alt="ContractorSignature"
                    style={{ height: 50 }}
                  />
                ) : generatingPdf || token ? (
                  <div style={{ height: 50 }} />
                ) : !editable ? (
                  proposalDetails?.estimateDetails?.signedByOrganization
                    ?.signature ? (
                    <Button
                      type="primary"
                      className="add-btn proposal_btn"
                      size="large"
                      onClick={() =>
                        setESignatureOpenFor(SIGNATURE_FOR.CONTRACTOR)
                      }
                      disabled={!editable}
                    >
                      Add Signature
                    </Button>
                  ) : (
                    <div style={{ height: 50 }} />
                  )
                ) : (
                  <Button
                    type="primary"
                    className="add-btn proposal_btn"
                    size="large"
                    onClick={() =>
                      setESignatureOpenFor(SIGNATURE_FOR.CONTRACTOR)
                    }
                    disabled={!editable}
                  >
                    Add Signature
                  </Button>
                )}
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <div className="border-bottom-1 pb-2 customer_section">
              <label>Customer Name</label>
              <input
                className="customer_name"
                value={
                  proposalDetails?.estimateDetails?.signedByClient?.name || ""
                }
                style={{ fontSize: 16, width: "100%" }}
              />
            </div>
          </Col>

          <Col xs={12}>
            <div className="border-bottom-1 pb-2 customer_section">
              <label>Company</label>
              <input
                className="customer_name"
                value={
                  proposalDetails?.estimateDetails?.signedByOrganization
                    ?.signature
                    ? token
                      ? proposalDetails?.organizationDetails?.name
                      : organizationInfo?.organizationName ||
                        userDetails?.organization?.name ||
                        proposalDetails?.estimateDetails?.signedByOrganization
                          ?.name ||
                        ""
                    : ""
                }
                style={{ fontSize: 16, width: "100%" }}
              />
            </div>
          </Col>
          <Col xs={12}>
            <div className="border-bottom-1 pb-2  customer_section">
              <label>Date</label>
              <input
                className="customer_name"
                value={
                  proposalDetails?.estimateDetails?.signedByClient?.date
                    ? moment(
                        proposalDetails?.estimateDetails?.signedByClient?.date,
                      ).format("MM/DD/YYYY")
                    : ""
                }
                style={{ fontSize: 16, width: "100%" }}
              />
            </div>
          </Col>
          <Col xs={12}>
            <div className="border-bottom-1 pb-2 customer_section">
              <label>Date</label>
              <input
                className="customer_name"
                value={
                  proposalDetails?.estimateDetails?.signedByOrganization?.date
                    ? moment(
                        proposalDetails?.estimateDetails?.signedByOrganization
                          ?.date,
                      ).format("MM/DD/YYYY")
                    : ""
                }
                style={{ fontSize: 16, width: "100%" }}
              />
            </div>
          </Col>
        </Row>
      </div>
      {proposalDetails?.estimateDetails?.signedByClient &&
        proposalDetails?.estimateDetails?.signedByOrganization && (
          <ProposalCertificationOfCompletion
            settings={settings}
            proposalDetails={proposalDetails}
            generatingPdf={generatingPdf}
          />
        )}
    </Card>
  );
};

export default ProposalSignature;
