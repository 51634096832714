import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { createValidUrl } from "../../../utils/commonFuctions";
import { replaceMentions } from "../../../utils/suggestions";
import { Featuredicon } from "../../../utils/svg.file";

const ProposalAboutUs = ({
  proposalDetails,
  setEditProposalSettings,
  settings,
  generatingPdf,
}) => {
  const userDetails = useSelector((state) => state?.user);
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width:992px)").matches,
  );
  const [params] = useSearchParams();

  const token = params.get("token");

  console.log("first", userDetails);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width:992px)");
    const handler = (e) => setMatches(e.matches);
    mediaQuery.addEventListener("change", handler);

    // Cleanup on unmount
    return () => mediaQuery.removeEventListener("change", handler);
  }, []);
  return (
    <Card
      bordered={false}
      style={{
        borderRadius: "12px",
        boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
        overflow: "hidden",
      }}
      className="mb-3 proposal-card-h-fix"
      id="aboutUs"
      bodyStyle={{ minHeight: "509px" }}
    >
      <div className="contract-bg-section">
        <div className="d-flex align-items-center gap-3 justify-content-between pe-4">
          <div
            className="abouts-btn"
            style={{
              backgroundColor: settings?.templateColor,
              color: settings?.templateTextColor,
              padding: "20px 60px",
              ...(generatingPdf && {
                fontSize: "35px",
                fontWeight: "bold",
              }),
            }}
          >
            About Us
          </div>
          {(userDetails?.organization?.logo ||
            proposalDetails?.organizationDetails?.logo) && (
            <div className="ms-auto">
              <img
                src={createValidUrl(
                  token
                    ? proposalDetails?.organizationDetails?.logo
                    : userDetails?.organization?.logo,
                )}
                alt="about-us"
                className="company-logo"
              />
            </div>
          )}
        </div>
      </div>
      <div className="aboutContent aboutContent-flex">
        <div
          className="flex-fill"
          style={{
            height: matches
              ? !proposalDetails?.aboutUs?.photo
                ? 652
                : 538
              : "",
          }}
        >
          <h2
            className="proposal-title-h2"
            dangerouslySetInnerHTML={{
              __html: replaceMentions(
                proposalDetails?.aboutUs?.title,
                proposalDetails?.customerDetails,
                token
                  ? proposalDetails?.organizationDetails
                  : userDetails?.organization,
                token ? proposalDetails?.userDetails : userDetails,
              ),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: replaceMentions(
                proposalDetails?.aboutUs?.description,
                proposalDetails?.customerDetails,
                token
                  ? proposalDetails?.organizationDetails
                  : userDetails?.organization,
                token ? proposalDetails?.userDetails : userDetails,
              ),
            }}
          />
        </div>
        {proposalDetails?.aboutUs?.photo ? (
          <div
            className="bg-about-contact"
            {...{
              ...(generatingPdf && {
                style: {
                  position: "relative",
                  margin: "0px -24px 0px -24px",
                },
              }),
            }}
          >
            <img
              src={createValidUrl(proposalDetails?.aboutUs?.photo)}
              alt="File Preview"
              style={{ width: "100%", height: 387 }}
              className="aboutImg_covr"
            />
          </div>
        ) : generatingPdf ? (
          <div style={{ height: 50 }} />
        ) : !token ? (
          <div
            className="contract-upload-section"
            onClick={() => setEditProposalSettings({ aboutUs: true })}
            style={{ cursor: "pointer" }}
          >
            <p>About Us Photo</p>
            <div className="upload-ab-contract mb-5">
              {Featuredicon}
              <h6>
                Click to upload <span>Or</span>
              </h6>
              <p>Upload a Jpg, Jpeg or PNG File</p>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </Card>
  );
};

export default ProposalAboutUs;
