import { Card } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { createValidUrl } from "../../../utils/commonFuctions";
import { NumberFormat } from "../../../utils/numberFormat";
import ProposalNextStep from "./ProposalNextStep";

const ProposalPaymentTerms = ({ settings, proposalDetails, generatingPdf }) => {
  const userDetails = useSelector((state) => state?.user);
  const [params] = useSearchParams();
  const token = params.get("token");
  return (
    <Card
      bordered={false}
      style={{
        borderRadius: "12px",
        boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
      }}
      className="mb-3 proposal-card-h-fix"
    >
      <div className="contract-bg-section">
        <div className="d-flex align-items-center gap-3 justify-content-between pe-4">
          <div
            className="abouts-btn"
            style={{
              backgroundColor: settings?.templateColor,
              color: settings?.templateTextColor,
              padding: "20px 60px",
              ...(generatingPdf && {
                fontSize: "35px",
                fontWeight: "bold",
              }),
            }}
          >
            Payment Terms
          </div>
          {(userDetails?.organization?.logo ||
            proposalDetails?.organizationDetails?.logo) && (
            <div className="ms-auto">
              <img
                src={createValidUrl(
                  token
                    ? proposalDetails?.organizationDetails?.logo
                    : userDetails?.organization?.logo,
                )}
                alt="payment-terms"
                className="company-logo"
              />
            </div>
          )}
        </div>
      </div>
      <div className="mt-5 overflow-x-auto">
        <table className="paverbox" width="100%">
          {proposalDetails?.estimateDetails?.paymentTerms?.map((obj) => (
            <tr>
              <td style={{ width: "500px" }}>{obj?.title}</td>
              <td
                {...{
                  ...(obj?.isFix
                    ? {
                        colSpan: 2,
                        style: {
                          textAlign: settings?.percentage ? "left" : "right",
                        },
                      }
                    : {
                        ...(!settings?.percentage && { colSpan: 2 }),
                        style: {
                          fontWeight: 500,
                        },
                      }),
                }}
              >
                {NumberFormat(obj?.totalPrice)}
              </td>
              {settings?.percentage && !obj?.isFix && <td>({obj?.value}%)</td>}
            </tr>
          ))}
        </table>
      </div>
      {settings?.nextSteps && (
        <ProposalNextStep
          settings={settings}
          proposalDetails={proposalDetails}
          generatingPdf={generatingPdf}
        />
      )}
    </Card>
  );
};

export default ProposalPaymentTerms;
