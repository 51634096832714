/* eslint-disable no-unused-vars */
import { EditOutlined } from "@ant-design/icons";
import { Button, Card, Col, Image, Row } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import Cropper from "cropperjs";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { updateEstimateLineItemImageAPI } from "../../../api/v2/userEstimation";
import ModalDailog from "../../../common/components/ModalDialogue";
import { createValidUrl } from "../../../utils/commonFuctions";
import { allImageFilesExtension } from "../../../utils/constants/constants";
import { fileToBase64 } from "../../../utils/fileBase64";
import { modifyPhoneNumber } from "../../../utils/javascript";
import { NumberFormat } from "../../../utils/numberFormat";
import CustomModal from "../../invoicing/details/CustomModal";
import EditLineItemPhoto from "./forms/EditLineItemPhoto";
import ProposalSummary from "./ProposalSummary";

const ProposalEstimate = ({
  settings,
  proposalDetails,
  setProposalDetails,
  generatingPdf,
  organizationInfo,
}) => {
  const imageRef = useRef(null);
  let cropper = null;
  const cropperRef = useRef(null);
  const userDetails = useSelector((state) => state?.user);
  const [imageUrl, setImageURL] = useState();
  const [photo, setPhoto] = useState();
  const [showCropper, setShowCropper] = useState(false);
  const [errors, setErrors] = useState(null);
  const [hover, setHover] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [saveLineItemPhoto, setSaveLineItemPhoto] = useState(false);
  const [file, setFile] = useState(null);
  const [dragId, setDragId] = useState("");
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [lineItemPhotos, setLineItemPhotos] = useState([]);

  const customRequest = ({ onSuccess, file: fileData }) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      if (imageRef.current) {
        if (cropperRef.current) {
          cropperRef.current.destroy();
        }
        imageRef.current.src = e.target.result;
        cropperRef.current = new Cropper(imageRef.current, {
          aspectRatio: 234 / 109,
          zoomable: true,
          cropBoxResizable: true,
        });
        const fileBase64 = await fileToBase64(fileData);
        setFile(fileBase64);
        onSuccess();
      }
    };
    reader.onerror = () => {
      setShowCropper(false);
    };
    reader.readAsDataURL(fileData);
  };
  const validateImage = (file, selectedLineItem) => {
    const maxSize = 100 * 1024 * 1024;
    if (!allImageFilesExtension.includes(file?.type)) {
      if (cropper) {
        cropper.destroy();
      }
      imageRef.current.src = null;
      cropper = null;
      setDragId("");
      setErrors((prev) => ({ ...prev, file: "Invalid file selected" }));
      return false;
    }
    if (file.size > maxSize) {
      if (cropper) {
        cropper.destroy();
      }
      imageRef.current.src = null;
      cropper = null;
      setDragId("");
      setErrors((prev) => ({ ...prev, file: "Invalid file selected" }));
      return false;
    }
    setDragId(selectedLineItem?.draggableId);
    setErrors((prev) => ({ ...prev, file: "" }));
    return true;
  };
  const handleCrop = async () => {
    if (cropperRef.current) {
      const croppedImageDataUrl = cropperRef.current
        .getCroppedCanvas({ fillColor: "#fff" })
        .toDataURL("image/jpeg");
      cropperRef.current
        .getCroppedCanvas({ fillColor: "#fff" })
        .toBlob((blob) => {
          if (blob) {
            const file = new File([blob], "cropped-image.jpg", {
              type: "image/jpeg",
            });
            setPhoto(file);
          }
        });
      if (croppedImageDataUrl) {
        setImageURL(croppedImageDataUrl || null);
        cropperRef.current.destroy();
      }
    }
    setShowCropper(false);
    setSaveLineItemPhoto(true);
  };
  const handleSaveContent = async () => {
    const response = await updateEstimateLineItemImageAPI(
      proposalDetails?.estimateDetails?._id,
      { draggableId: dragId, newImage: file },
    );
    if (response?.remote === "success") {
      setProposalDetails((prev) => ({
        ...prev,
        estimateDetails: {
          ...prev?.estimateDetails,
          services: [...response?.data?.data?.services],
        },
      }));
    }
    setSaveLineItemPhoto(false);
  };

  const getFileData = async (imgURL, linkArr, ind) => {
    const headers = new Headers();
    // headers.set("Access-control-allow-origin", true);
    // headers.set("Accept", "image/*");
    try {
      const response = await fetch(imgURL, {
        headers,
      });

      console.log("response", response);
      if (!response.ok) {
        linkArr[ind] = "";
        throw new Error("Failed to fetch image");
      }

      const blob = await response.blob();

      const mimeType = response.headers.get("content-type");
      const blobWithMIME = new Blob([blob], { type: mimeType });

      const objectURL = URL.createObjectURL(blobWithMIME);
      linkArr[ind] = objectURL;
    } catch (error) {
      console.log("Error while convert to image => ", error);
      linkArr[ind] = "";
    }
    return linkArr;
  };

  useEffect(() => {
    let links = [];
    if (proposalDetails?.estimateDetails?.services?.length) {
      proposalDetails?.estimateDetails?.services?.forEach(async (obj, ind) => {
        links = await getFileData(createValidUrl(obj?.photo), links, ind);
      });
    }
    setLineItemPhotos(links);
  }, [proposalDetails?.estimateDetails?.services]);

  return (
    <>
      <Card
        bordered={false}
        style={{
          borderRadius: "12px",
          boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
        }}
        className="mb-3 proposal-card-h-fix"
        id="estimate"
      >
        <div className="contract-bg-section">
          <div className="d-flex align-items-center gap-3 justify-content-between pe-4">
            <div
              className="abouts-btn"
              style={{
                backgroundColor: settings?.templateColor,
                color: settings?.templateTextColor,
                padding: "20px 60px",
                ...(generatingPdf && {
                  fontSize: "35px",
                  fontWeight: "bold",
                }),
              }}
            >
              Estimate
            </div>
            {(userDetails?.organization?.logo ||
              proposalDetails?.organizationDetails?.logo) && (
              <div className="ms-auto">
                <img
                  src={createValidUrl(
                    token
                      ? proposalDetails?.organizationDetails?.logo
                      : userDetails?.organization?.logo,
                  )}
                  alt="estimate-logo"
                  className="company-logo"
                />
              </div>
            )}
          </div>
        </div>
        <div className="overtext p-30">
          <Row gutter={[12, 12]}>
            <Col xs={6} lg={6}>
              <div className="overtitle">
                <h4
                  {...{
                    style: {
                      whiteSpace: "nowrap",
                      ...(generatingPdf && {
                        color: "#3d414a",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        lineHeight: "31.855px",
                        letterSpacing: "-0.591px",
                      }),
                    },
                  }}
                >
                  Prepared For
                </h4>
                {/* Customer Name */}
                <p>{proposalDetails?.customerDetails?.fullName}</p>
              </div>
            </Col>
            <Col xs={8} lg={8}>
              <div className="overtitle">
                <h4
                  {...{
                    style: {
                      whiteSpace: "nowrap",
                      ...(generatingPdf && {
                        color: "#3d414a",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        lineHeight: "31.855px",
                        letterSpacing: "-0.591px",
                      }),
                    },
                  }}
                >
                  Project Address
                </h4>
                <div className="contct-address">
                  {/* Project Address */}
                  {proposalDetails?.customerProject?.projectAddress ||
                    proposalDetails?.customerDetails?.address ||
                    ""}
                </div>
              </div>
            </Col>
            <Col xs={10} lg={10}>
              <div className="overtitle">
                <h4
                  {...{
                    style: {
                      whiteSpace: "nowrap",
                      ...(generatingPdf && {
                        color: "#3d414a",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        lineHeight: "31.855px",
                        letterSpacing: "-0.591px",
                      }),
                    },
                  }}
                >
                  Company Information
                </h4>
                <p>
                  {token
                    ? proposalDetails?.organizationDetails?.name
                    : organizationInfo?.organizationName}
                </p>
                {/* Company phone number */}
                <p>
                  {modifyPhoneNumber(
                    token
                      ? proposalDetails?.userDetails?.contactNo
                      : organizationInfo?.assignedUserNumber,
                  )}
                </p>
                <div className="contct-address">
                  {/* Company email */}
                  {token
                    ? proposalDetails?.userDetails?.email || ""
                    : organizationInfo?.assignedUserEmail || ""}
                </div>
                <p>
                  {token
                    ? proposalDetails?.organizationDetails?.address
                    : organizationInfo?.organizationAddress}
                </p>
              </div>
            </Col>
          </Row>
          <div className="scope-work mb-3 mt-4">
            <h4
              style={{
                fontSize: 25,
                fontWeight: generatingPdf ? "bold" : 600,
                borderBottom: "1px solid #9f9f9f",
              }}
            >
              Scope Of Work
            </h4>
            {proposalDetails?.estimateDetails?.services?.map((obj, ind) => (
              <div className="scop-work mb-3 mt-4">
                <div
                  className="d-flex align-items-center gap-3 justify-content-between contract-dg pe-3"
                  style={{
                    backgroundColor: settings?.templateColor,
                    color: settings?.templateTextColor,
                    minHeight: 40,
                  }}
                >
                  <div className="d-flex align-items-center gap-3">
                    {settings?.lineItemPhotos && (
                      <div
                        className="imgbox-contract"
                        style={{
                          width: 80,
                        }}
                      >
                        {!token ? (
                          <Dragger
                            name={`${obj?._id}-photo`}
                            maxCount={1}
                            customRequest={customRequest}
                            showUploadList={false}
                            accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
                            onRemove={() => setShowCropper(false)}
                            onStart={() => setShowCropper(true)}
                            className="m-0 p-0 estimate-line-item-photo"
                            beforeUpload={(file) => validateImage(file, obj)}
                            style={{
                              width: "100%",
                              cursor: "pointer",
                              pointerEvents: "all",
                            }}
                          >
                            {!generatingPdf ? (
                              <div
                                onMouseEnter={() => setHover({ [ind]: true })}
                                onMouseLeave={() => setHover({ [ind]: false })}
                              >
                                <img
                                  src={createValidUrl(obj?.photo)}
                                  alt="lead-1-img"
                                  style={{ width: "100%", height: "40px" }}
                                  // preview={false}
                                  // wrapperStyle={{ width: "100%" }}
                                />
                                {hover?.[ind] && !token && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                      backgroundColor: "#484D59BF",
                                      color: "white",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontSize: "16px",
                                    }}
                                  >
                                    <EditOutlined />
                                  </div>
                                )}
                              </div>
                            ) : (
                              <img
                                src={lineItemPhotos?.[ind]}
                                alt="lead-1-img"
                                style={{ width: "100%", height: "40px" }}
                              />
                            )}
                          </Dragger>
                        ) : (
                          <Image
                            src={createValidUrl(obj?.photo)}
                            alt="lead-1-img"
                            style={{ width: "100%", height: "40px" }}
                            wrapperStyle={{ width: "100%" }}
                          />
                        )}
                      </div>
                    )}
                    <h4
                      style={{
                        color: settings?.templateTextColor,
                        ...(!settings?.lineItemPhotos && { paddingLeft: 18 }),
                      }}
                    >
                      {obj?.displayName}
                    </h4>
                  </div>
                  {settings?.lineItemPrice && (
                    <div
                      className="contactCount"
                      style={{
                        color: settings?.templateTextColor,
                      }}
                    >
                      {NumberFormat(
                        obj?.actualServices?.find(
                          (obj) => obj?.type === "original",
                        )?.totalServiceCharge,
                      )}
                    </div>
                  )}
                </div>
                {(settings?.crewNotes || settings?.clientDescription) && (
                  <ul className="list-contract">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: settings?.crewNotes
                          ? obj?.isEditCrewNotes
                            ? obj?.processedCrewNotes?.replace(/\n/g, "<br/>")
                            : obj?.processedCrewNotes
                          : obj?.isEditClientContract
                          ? obj?.processedClientContract?.replace(
                              /\n/g,
                              "<br/>",
                            )
                          : obj?.processedClientContract,
                      }}
                    />
                  </ul>
                )}
              </div>
            ))}
          </div>
          {!settings?.summary?.details?.lineItems?.active && (
            <ProposalSummary
              settings={settings}
              proposalDetails={proposalDetails}
            />
          )}
        </div>
      </Card>
      <ModalDailog
        isModalOpen={showCropper}
        handleCancel={() => setShowCropper(false)}
        closable
        title="Edit image"
        footer={[
          <Button onClick={() => setShowCropper(false)}>Cancel</Button>,
          <Button onClick={handleCrop} type="primary">
            Ok
          </Button>,
        ]}
      >
        <>
          <div>
            <img ref={imageRef} alt="" />
          </div>
        </>
      </ModalDailog>
      <CustomModal
        isModalOpen={saveLineItemPhoto}
        handleCancel={() => setSaveLineItemPhoto(false)}
        closeIcon={<></>}
      >
        <EditLineItemPhoto
          customRequest={customRequest}
          setShowCropper={setShowCropper}
          handleSaveContent={handleSaveContent}
          imageUrl={imageUrl}
          validateImage={validateImage}
          updating={updating}
          handleCancel={() => setSaveLineItemPhoto(false)}
          errors={errors}
        />
      </CustomModal>
    </>
  );
};

export default ProposalEstimate;
