/* eslint-disable no-unused-vars */
import { Card } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { createValidUrl } from "../../../utils/commonFuctions";

const ProposalAttachments = ({ settings, proposalDetails, generatingPdf }) => {
  const userDetails = useSelector((state) => state?.user);
  const [params] = useSearchParams();

  const token = params.get("token");
  return (
    <Card
      bordered={false}
      style={{
        borderRadius: "12px",
        boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
      }}
      className="mb-3 proposal-card-h-fix"
    >
      <div className="contract-bg-section">
        <div className="d-flex align-items-center gap-3 justify-content-between pe-4">
          <div
            className="abouts-btn"
            style={{
              backgroundColor: settings?.templateColor,
              color: settings?.templateTextColor,
              padding: "20px 60px",
              fontSize: "35px",
              fontWeight: "bold",
            }}
          >
            Attachments
          </div>
          {(userDetails?.organization?.logo ||
            proposalDetails?.organizationDetails?.logo) && (
            <div className="ms-auto">
              <img
                src={createValidUrl(
                  token
                    ? proposalDetails?.organizationDetails?.logo
                    : userDetails?.organization?.logo,
                )}
                alt="signature"
                className="company-logo"
              />
            </div>
          )}
        </div>
      </div>
      <div className="mt-5">
        {proposalDetails?.estimateDetails?.attachment &&
          (!proposalDetails?.estimateDetails?.attachment?.includes(".pdf") ? (
            <img
              src={createValidUrl(proposalDetails?.estimateDetails?.attachment)}
              alt="proof-attachments"
              width="100%"
              height={819}
            />
          ) : generatingPdf &&
            proposalDetails?.estimateDetails?.attachment?.includes(".pdf") ? (
            <p
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                height: "816px",
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              Attached PDF Downloaded Separately
            </p>
          ) : (
            <iframe
              src={createValidUrl(proposalDetails?.estimateDetails?.attachment)}
              width="100%"
              height={819}
              title="attachment"
              style={{ display: "block" }}
              allowFullScreen={false}
              allow=""
              className="attachment"
            />
          ))}
      </div>
    </Card>
  );
};

export default ProposalAttachments;
