import { Button, Form, Input, message } from "antd";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { useEffect, useState } from "react";

import { updateProposalFinancing } from "../../../../api/proposal";
import { MAX_SIZE_DESC } from "../../../../utils/constants/constants";
import regex from "../../../../utils/regex";
import { replaceWithoutTagValue } from "../../../../utils/suggestions";
import ProposalEditor from "./ProposalEditor";

const EditFinancingForm = ({
  proposalDetails,
  handleCancel,
  setProposalDetails,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [financingLink, setFinancingLink] = useState("");
  const [errors, setErrors] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (value) => {
    const valid = regex.validURL(value);
    if (!valid) {
      setErrors({ link: "Please enter valid link with https or http" });
    } else {
      setErrors(null);
    }
    setFinancingLink(value);
  };

  const handleSubmit = async (isDefault = false) => {
    setUpdating(true);
    const formData = new FormData();
    formData.append(
      "description",
      replaceWithoutTagValue(
        draftToHtml(convertToRaw(editorState.getCurrentContent())),
      ),
    );
    if (financingLink) {
      formData.append(
        "link",
        financingLink?.includes("http://") ||
          financingLink?.includes("https://")
          ? financingLink
          : `https://${financingLink}`,
      );
    }
    const response = await updateProposalFinancing({
      estId: proposalDetails?.estimateId,
      data: formData,
      isDefault,
    });
    if (response?.remote === "success") {
      setProposalDetails((prev) => ({
        ...prev,
        ...response?.data?.data,
      }));
      setUpdating(false);
      handleCancel();
    } else {
      console.log(
        "Error while updating financing details => ",
        response?.errors?.errors,
      );
      message.error(
        response?.errors?.errors?.error ||
          response?.errors?.errors?.message ||
          response?.errors?.errors,
      );
      setUpdating(false);
    }
  };

  useEffect(() => {
    if (proposalDetails?.financing) {
      const templateBody = htmlToDraft(
        proposalDetails?.financing?.description || "",
      );
      const contentState = ContentState.createFromBlockArray(
        templateBody.contentBlocks,
      );
      setEditorState(EditorState.createWithContent(contentState));
      setFinancingLink(proposalDetails?.financing?.link);
    }
  }, [proposalDetails?.financing]);
  useEffect(() => {
    if (editorState) {
      const desc = replaceWithoutTagValue(
        draftToHtml(convertToRaw(editorState.getCurrentContent())),
      );
      if (desc?.length > MAX_SIZE_DESC) {
        setError(`Description should be less than ${MAX_SIZE_DESC} chars`);
      } else {
        setError("");
      }
    }
  }, [editorState]);
  return (
    <Form layout="vertical" className="formstate">
      <Form.Item label="Financing Info">
        <ProposalEditor
          editorState={editorState}
          setEditorState={setEditorState}
        />
        {error && <p className="text-danger">{error}</p>}
      </Form.Item>

      <Form.Item
        label="Link To Financing Application"
        className="application-link"
      >
        <Input
          placeholder="www.examplelink.com"
          value={financingLink}
          onChange={(e) => handleChange(e?.target?.value)}
        />
        {errors?.link && <p className="m-0 text-danger">{errors?.link}</p>}
      </Form.Item>
      <Form.Item>
        <div className="d-flex align-items-center gap-2 justify-content-end">
          <Button className="cancel-btn" onClick={() => handleCancel()}>
            Cancel
          </Button>
          <Button
            className="clear-filter"
            disabled={updating}
            onClick={() => handleSubmit(true)}
          >
            Save For This Estimate
          </Button>
          <Button
            className="apply-btn"
            type="primary"
            disabled={updating}
            onClick={() => handleSubmit(true)}
          >
            Save As Default
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
export default EditFinancingForm;
