import { Button, Form, message } from "antd";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { updateProposalTermsCondition } from "../../../../api/proposal";
import { replaceWithoutTagValue } from "../../../../utils/suggestions";
import ProposalEditor from "./ProposalEditor";

const EditTermsAndConditionsForm = ({
  handleCancel,
  proposalDetails,
  setProposalDetails,
}) => {
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty(),
  );
  const [updating, setUpdating] = useState(false);
  const organization = useSelector((state) => state?.user?.organization);

  const handleSubmit = async (isDefault = false) => {
    setUpdating(true);
    const formData = new FormData();
    formData.append(
      "terms",
      replaceWithoutTagValue(
        draftToHtml(convertToRaw(editorState.getCurrentContent())),
        null,
        organization,
      ),
    );
    formData.append("isSaveDefault", isDefault);

    const response = await updateProposalTermsCondition({
      estId: proposalDetails?.estimateId,
      data: formData,
      isDefault,
    });
    if (response?.remote === "success") {
      setProposalDetails((prev) => ({
        ...prev,
        ...response?.data?.data,
        estimateDetails: {
          ...prev?.estimateDetails,
          termAndCondition: replaceWithoutTagValue(
            draftToHtml(convertToRaw(editorState.getCurrentContent())),
            null,
            organization,
          ),
        },
      }));
      setUpdating(false);
      handleCancel();
    } else {
      console.log(
        "Error while updating terms and condition => ",
        response?.errors?.errors,
      );
      message.error(
        response?.errors?.errors?.error ||
          response?.errors?.errors?.message ||
          response?.errors?.errors,
      );
      setUpdating(false);
    }
  };

  useEffect(() => {
    if (proposalDetails?.estimateDetails?.termAndCondition) {
      const templateBody = htmlToDraft(
        proposalDetails?.estimateDetails?.termAndCondition || "",
      );
      const contentState = ContentState.createFromBlockArray(
        templateBody.contentBlocks,
      );
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [proposalDetails?.estimateDetails?.termAndCondition]);
  return (
    <Form layout="vertical" className="formstate ">
      <Form.Item label="Text">
        <ProposalEditor
          editorState={editorState}
          setEditorState={setEditorState}
        />
      </Form.Item>

      <Form.Item>
        <div className="d-flex align-items-center gap-2 justify-content-end">
          <Button className="clear-filter" onClick={() => handleCancel()}>
            Cancel
          </Button>
          <Button
            className="clear-filter"
            disabled={updating}
            onClick={() => handleSubmit()}
          >
            Save For This Estimate
          </Button>
          <Button
            className="apply-btn"
            type="primary"
            disabled={updating}
            onClick={() => handleSubmit(true)}
          >
            Save As Default
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
export default EditTermsAndConditionsForm;
