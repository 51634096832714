/* eslint-disable no-unused-vars */
import "./contract-preview.css";

import { Button, Card, Col, Image, message, Modal, Row } from "antd";
import html2pdf from "html2pdf.js";
import React, { useEffect, useRef, useState } from "react";
import { ChromePicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { /* useNavigate, */ useSearchParams } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

import {
  sendConfirmationEmailAndSaveContractPdfForClientAPI,
  viewedEstimation,
} from "../../../api/contract";
import {
  generateQBPaymentLink,
  generateSquarePaymentLink,
  generateStripePaymentLink,
  getSquareCharge,
} from "../../../api/invoice";
import {
  getOrganizationDetails,
  getProposalForCustomer,
  getProposalSettingDetails,
  getProposalSettingList,
  signProposalByClient,
  signProposalByOrganization,
  updateProposalSettings,
} from "../../../api/proposal";
import ModalDailog from "../../../common/components/ModalDialogue";
import { SmallLoader } from "../../../common/components/small-loader";
import {
  getSquareInfoAction,
  getTagsDetails,
  modalToggleAction,
} from "../../../redux/invoice/invoice.action";
import { createValidUrl } from "../../../utils/commonFuctions";
import regex from "../../../utils/regex";
import { closex, payCash } from "../../../utils/svg.file";
import { ESignature } from "../../estimates/contractPreview/e-signature.component";
import CustomModal from "../../invoicing/details/CustomModal";
import EmailModal from "../../invoicing/invoice-setting/emailModal";
import EditAboutUsForm from "./forms/EditAboutUsForm";
import EditAttachmentUpload from "./forms/EditAttachmentUpload";
import EditCoverPageForm from "./forms/EditCoverPageForm";
import EditFinancingForm from "./forms/EditFinancingForm";
import EditNextStepsForm from "./forms/EditNextSteps";
import EditPastProjectForm from "./forms/EditPasProjectsForm";
import EditProposedScheduleForm from "./forms/EditProposedScheduleForm";
import EditTermsAndConditionsForm from "./forms/EditTermsAndConditionsForm";
import {
  addExtraPercentageAmount,
  getBase64OfHTMLDivComponentAsPDF,
  getTextColor,
} from "./helper";
import {
  ACHPaymentPopup,
  CardPaymentFeePopup,
  CreditCardPaymentPopup,
  PayDepositePopup,
  QBBillingDetail,
  SquareAchPaymentModal,
} from "./Modal";
import ProposalAboutUs from "./ProposalAboutUs";
import ProposalAttachments from "./ProposalAttachments";
import ProposalCoverPage from "./ProposalCoverPage";
import ProposalEstimate from "./ProposalEstimate";
import ProposalFinancing from "./ProposalFinancing";
import ProposalHeader from "./ProposalHeader";
import ProposalNextStep from "./ProposalNextStep";
import ProposalPastProjects from "./ProposalPastProjects";
import ProposalPaymentTerms from "./ProposalPaymentTerms";
import ProposalProposedSchedule from "./ProposalProposedSchedule";
import ProposalSettings from "./ProposalSettings";
import ProposalSignature from "./ProposalSignature";
import ProposalSummary from "./ProposalSummary";
import ProposalTermsNCondition from "./ProposalTermsNCondition";
const GlobalStyle = createGlobalStyle`
  @media only screen and (min-width: 1024px) {
    html, body {
      overflow: auto;
    }
  }
`;
const SIGNATURE_FOR = Object.freeze({
  CONTRACTOR: "Contractor",
  CUSTOMER: "Customer",
});

async function toDataURL(url, callback) {
  const img = new Image();
  img.crossOrigin = "Anonymous"; // Enable cross-origin requests if needed
  img.onload = function () {
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    // Determine the MIME type based on the image's file extension
    let mimeType;
    if (url.endsWith(".jpg") || url.endsWith(".jpeg")) {
      mimeType = "image/jpeg";
    } else if (url.endsWith(".png")) {
      mimeType = "image/png";
    } else if (url.endsWith(".gif")) {
      mimeType = "image/gif";
    } else {
      // Default to PNG if the format is not recognized
      mimeType = "image/png";
    }

    const dataURL = canvas.toDataURL(mimeType);
    callback(dataURL);
  };
  img.src = url;
}

async function compareUrlWithBase64(url, base64) {
  return new Promise((resolve) => {
    if (base64 === "") resolve(false);
    else {
      toDataURL(url, (e) => {
        resolve(e === base64);
      });
    }
  });
}

export default function ContractPreviewNew() {
  // const navigate = useNavigate();
  const currentUserDetail = useSelector((state) => state.user);
  const editable = true;
  const [searchParams] = useSearchParams();
  const [proposalList, setProposalList] = useState([]);
  const [selectedProposal, setSelectedProposal] = useState("");
  const [selectedProposalSettings, setSelectedProposalSettings] = useState({});
  const [loading, setLoading] = useState(false);
  const estimationId = searchParams.get("estimationId");
  const isEstimationViewed = localStorage.getItem(
    selectedProposalSettings?.estimateDetails?._id,
  );
  const [viewed, setViewed] = useState(false);
  const [switchLoading, setSwitchLoading] = useState();
  const [editProposalSettings, setEditProposalSettings] = useState();
  const [contractorSigningContract, setContractorSigningContract] =
    useState(false);
  const [eSignatureOpenFor, setESignatureOpenFor] = useState("");
  const [contractorSignature, setContractorSignature] = useState("");
  const [generatingPdf, setGeneratingPdf] = useState(false);
  const [organizationLogoBase64, setOrganizationLogoBase64] = useState("");
  const [customerSignature, setCustomerSignature] = useState("");
  const [showCustomerSignatureWarning, setShowCustomerSignatureWarning] =
    useState(false);
  const [color, setColor] = useState("");
  const token = searchParams.get("token");
  const contentRef = useRef(null);
  const userDetails = useSelector((state) => state?.user);
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [showCardPayment, setShowCardPayment] = useState(false);
  const [showACHPayment, setShowACHPayment] = useState(false);
  const [showCardPaymentFee, setShowCardPaymentFee] = useState(false);
  const [prevPopup, setPrevPopup] = useState("");
  const [billingEmail, setBillingEmail] = useState("");
  const [billingError, setBillingError] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [squareACHPayment, setSquareACHPayment] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const dispatch = useDispatch();
  const [callingView, setCallingView] = useState(false);
  const [changingColor, setChangingColor] = useState(false);
  const { squarePaymentInfo, modalToggle } = useSelector(
    (state) => state?.invoice,
  );
  const [btnLoading, setBtnLoading] = useState(false);
  const [checkingSignature, setCheckingSignature] = useState(false);
  const [organizationInfo, setOrganizationInfo] = useState();
  const [depositPopupDetail, setDepositPopupDetail] = useState({
    invoiceSettings: {
      _id: "",
      depositPopup: false,
      depositPopupTemplate: {
        payWithCheckCash: false,
        payWithCreditCardAch: false,
        popUpTemplate: {
          body: "",
          title: "",
          welcomeNote: "",
        },
      },
    },
  });
  const handleSignatureByOrganization = async (
    contractorSignature,
    contractorName,
    signatureStyle,
  ) => {
    const key = "signatureByOrganization";
    message.loading({ content: "Signing Contract...", key, duration: 20 });
    setContractorSignature(contractorSignature);
    setGeneratingPdf(true);
    setContractorSigningContract(true);
    setTimeout(async () => {
      const signedContractPdf = await getBase64OfHTMLDivComponentAsPDF(
        "divToPrint",
        null,
        {
          margin: "0px",
          scale: 0.8,
        },
      );
      const res = await signProposalByOrganization({
        data: {
          contractorSignature,
          signedContractPdf,
          contractorName,
          signatureStyle,
        },
        estId: selectedProposalSettings?.estimateDetails?._id,
      });
      if (res.remote === "success") {
        const signedByOrganization =
          res.data.updatedEstimation?.signedByOrganization;
        toDataURL(
          createValidUrl(signedByOrganization?.signature),
          setContractorSignature,
        );
        message.success({ content: "Signed Successfully", key, duration: 2 });
        getProposalSettings(true);
      } else {
        message.error({ content: "Something went wrong!", key, duration: 2 });
      }
      setContractorSigningContract(false);
      setGeneratingPdf(false);
    }, 1000);
  };

  const handleAddClientSignature = async (
    customerSignature,
    fullName,
    signatureStyle,
  ) => {
    message.loading({
      content: "Signing Contract...",
      key: "updatable",
      duration: 20,
    });

    const payload = {
      customerId:
        selectedProposalSettings?.customerId ||
        selectedProposalSettings?.customerDetails?._id,
      proposalId: selectedProposalSettings?._id || selectedProposal,
      organizationId: selectedProposalSettings?.organizationId,
      customerSignature,
      signedByEmployeeId: token
        ? selectedProposalSettings?.userDetails?._id
        : userDetails?.id,
      customerName: fullName,
      signatureStyle,
      signedByOrganization:
        selectedProposalSettings?.estimateDetails?.signedByOrganization
          ?.signature,
    };

    if (!editable) {
      delete payload.signedByEmployeeId;
    }
    const res = await signProposalByClient({
      data: payload,
      estId: selectedProposalSettings?.estimateDetails?._id,
    });

    if (res.remote === "success") {
      setTimeout(async () => {
        const signedContractPdf = await getBase64OfHTMLDivComponentAsPDF(
          "divToPrint",
          null,
          {
            margin: "0px",
            scale: 0.8,
          },
        );
        const payload = {
          contractPdf: signedContractPdf,
          organizationId:
            selectedProposalSettings?.estimateDetails?.organizationId,
          estimationId: selectedProposalSettings?.estimateDetails?._id,
        };
        const res = await sendConfirmationEmailAndSaveContractPdfForClientAPI(
          payload,
          token,
        );
        if (res?.remote === "success") {
          message.success({
            content: "Signed Successfully",
            key: "updatable",
            duration: 2,
          });
          setGeneratingPdf(false);
          getProposalSettings();
        } else {
          message.error({
            content: "Something went wrong!",
            key: "updatable",
            duration: 2,
          });
          setGeneratingPdf(false);
        }
      }, 500);
    } else {
      message.error({
        content: "Something went wrong!",
        key: "updatable",
        duration: 2,
      });
      setShowCustomerSignatureWarning(true);
      setGeneratingPdf(false);
    }
  };

  const onSignatureSave = (e, title, fullName, signatureStyle) => {
    switch (eSignatureOpenFor) {
      case SIGNATURE_FOR.CONTRACTOR:
        if (e) {
          handleSignatureByOrganization(e, fullName, signatureStyle);
        }
        break;
      case SIGNATURE_FOR.CUSTOMER:
        if (e) {
          setCustomerSignature(e);
          setGeneratingPdf(true);
          // waiting to update state
          setTimeout(
            () => handleAddClientSignature(e, fullName, signatureStyle),
            1000,
          );
        }
        break;
      default:
        break;
    }
    setESignatureOpenFor("");
  };

  const getProposalSettings = async (checkSign = false) => {
    console.log("Over here #330 => ");
    if (token) {
      setLoading(true);
    }
    if (checkSign) {
      console.log("checkSign...");
      setCheckingSignature(true);
      message.loading({
        content: "Checking signature...",
        key: "checkingSignature",
        duration: 20,
      });
    }

    const response = await (token
      ? getProposalForCustomer({ token })
      : getProposalSettingDetails(estimationId));
    if (response.remote === "success") {
      const modRes = token ? response?.data?.data?.[0] : response?.data?.data;
      setSelectedProposalSettings(modRes);
      setSelectedProposal(modRes?.settings?._id);
      setCheckingSignature(false);
      if (
        modRes?.newEstimateDetails?.signedByClient?.signature ||
        modRes?.estimateDetails?.signedByClient?.signature
      ) {
        const isDepositPopupViewed =
          modRes?.estimateDetails?.isDepositPopupViewed;
        const depositPopup =
          modRes?.organizationDetails?.invoiceSettings.depositPopup;
        const shouldShowPopup = !isDepositPopupViewed && depositPopup;

        setDepositPopupDetail({
          invoiceSettings: {
            ...modRes?.organizationDetails?.invoiceSettings,
            openModal: shouldShowPopup,
          },
        });
      }
      if (checkSign) {
        message
          .success({
            content: "Signature confirmed",
            key: "checkingSignature",
            duration: 2,
          })
          .then(() => {
            console.log("inside then");
            setCheckingSignature(false);
          });
      }
      setLoading(false);
    } else {
      console.log("Error while getting proposal details => ", response);
      message.error(response?.errors?.errors);
      setLoading(false);
      setCheckingSignature(false);
    }
  };

  const getProposalList = async (needLoading = true) => {
    if (needLoading) setLoading(true);
    const response = await getProposalSettingList();
    if (response.remote === "success") {
      setProposalList(
        [...response?.data?.data]?.map((obj) => ({
          label: obj?.title,
          value: obj?._id,
        })),
      );
      setSelectedProposal((prev) => prev || response?.data?.data?.[0]?._id);
      getProposalSettings();
    } else {
      console.log("Error while getting proposal template list => ", response);
      message.error(response?.errors?.errors);
      if (needLoading) setLoading(false);
    }
  };

  const handleChangeToggle = async ({ data, name, openModal = "" }) => {
    console.log("data => ", data, name);
    setSwitchLoading({ [name]: true });
    const response = await updateProposalSettings({
      estimationId,
      data,
    });
    if (response.remote === "success") {
      setSelectedProposalSettings((prev) => ({
        ...prev,
        settings: {
          ...prev?.settings,
          ...response?.data?.data?.settings,
        },
      }));
      if (openModal && data?.[name]) {
        setEditProposalSettings({ [openModal]: true });
      }
      setSwitchLoading({ [name]: false });
    } else {
      console.log("Error while changing toggle => ", response);
      message.error(response?.errors?.errors);
      setSwitchLoading({ [name]: false });
    }
  };

  const handleColorChange = (color) => {
    setColor(color.hex);
    let data = selectedProposalSettings?.settings;
    data = {
      ...data,
      templateColor: color.hex,
    };
    setSelectedProposalSettings((prev) => ({
      ...prev,
      templateColor: data?.templateColor,
    }));
  };

  const setTemplateColor = async (isDefault = false) => {
    setChangingColor(true);
    const data = {
      templateColor: color,
      templateTextColor: getTextColor(color),
    };
    const response = await updateProposalSettings({
      estimationId,
      data,
      isDefault,
      proposalSettingId: selectedProposal,
    });
    if (response?.remote === "success") {
      setSelectedProposalSettings((prev) => ({
        ...prev,
        ...response?.data?.data,
      }));
      setEditProposalSettings({ templateColor: false });
      setChangingColor(false);
    } else {
      console.log("Error while setting template color => ", response);
      setChangingColor(false);
    }
  };

  useEffect(() => {
    if (!token) {
      getProposalList();
    } else {
      getProposalSettings();
    }
    if (estimationId) {
      dispatch(getTagsDetails(estimationId));
    }
  }, []);

  useEffect(() => {
    if (selectedProposalSettings?.organizationDetails?.signature) {
      const organizationSignature = createValidUrl(
        selectedProposalSettings?.organizationDetails.signature,
      );
      toDataURL(organizationSignature, setOrganizationLogoBase64);
    }
  }, [selectedProposalSettings]);

  const handleDownloadPdf = async () => {
    setGeneratingPdf(true);
    const element = document.getElementById("divToPrint");
    const options = {
      margin: 0,
      filename: "download.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, logging: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    // Generate PDF
    html2pdf()
      .set(options)
      .from(element)
      .save()
      .catch((err) => console.log("err => ", err));
    setGeneratingPdf(false);
  };

  const openLinkNewTab = (url) => {
    try {
      const a = document.getElementById("open-new-tab");
      a.setAttribute("href", url);
      const clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      a.dispatchEvent(clickEvent);
    } catch (ex) {
      console.log("ex", ex);
    }
  };

  const handleConfirmCardPayment = async () => {
    const paymentMethod =
      depositPopupDetail?.invoiceSettings?.activePaymentIntegration;
    const token = new URLSearchParams(location.search).get("token");
    const invoiceId = depositPopupDetail?.invoiceSettings?.invoice?._id;
    setPaymentType("");
    if (paymentMethod === "quickbooks") {
      if (token) {
        setPaymentType("CARD");
        setShowCardPaymentFee(false);
        dispatch(
          modalToggleAction({
            qbBillingDetails: true,
          }),
        );
      } else {
        message.error("Token not provided");
      }
    }
    if (paymentMethod === "stripe") {
      if (token) {
        const response = await generateStripePaymentLink(
          token,
          "CARD",
          invoiceId,
        );
        if (response.remote === "success") {
          if (response?.data?.data?.paymentLink) {
            window.location.href = response.data.data.paymentLink;
          } else {
            message.error(
              "There is some issues with the payments, Please try again later.",
            );
          }
          setShowCardPaymentFee(false);
        } else {
          message.error(response?.errors?.errors?.error);
        }
      } else {
        message.error("Token not provided");
      }
    }
    if (paymentMethod === "square") {
      if (token) {
        const response = await generateSquarePaymentLink({
          token,
          type: "CARD",
          invoiceId,
        });
        if (response.remote === "success") {
          if (response?.data?.data?.paymentLink) {
            window.location.href = response.data.data.paymentLink;
          } else {
            message.error(
              "There is some issues with the payments, Please try again later.",
            );
          }
          setShowCardPaymentFee(false);
        }
      } else {
        message.error("Token not provided");
      }
    }
  };

  const handleCardPaymentCancel = ({
    toggle,
    toggleNext = false,
    prevPopup = "",
  }) => {
    setShowCardPayment(toggle);
    setPrevPopup(prevPopup);
    if (
      selectedProposalSettings?.organizationDetails?.invoiceSettings
        ?.paymentIntegration?.extraChargeForCardPayment?.chargeEnabled
    ) {
      setShowCardPaymentFee(toggleNext);
      setShowCardPayment(false);
      setShowPaymentOptions(false);
    } else {
      handleConfirmCardPayment();
    }
  };

  const handleBackToCard = () => {
    if (prevPopup === "card") {
      setShowCardPayment(true);
      setShowPaymentOptions(false);
      setShowCardPaymentFee(false);
    }
    if (prevPopup === "options") {
      setShowPaymentOptions(true);
      setShowCardPayment(false);
      setShowCardPaymentFee(false);
    }
  };

  const handleACHPaymentCancel = async () => {
    const paymentMethod =
      depositPopupDetail?.invoiceSettings?.activePaymentIntegration;
    const token = new URLSearchParams(location.search).get("token");
    const invoiceId = depositPopupDetail?.invoiceSettings?.invoice?._id;
    setPaymentType("");
    if (paymentMethod === "quickbooks") {
      setPaymentType("ACH");
      setShowACHPayment(false);
      dispatch(
        modalToggleAction({
          qbBillingDetails: true,
        }),
      );
    }
    if (paymentMethod === "stripe") {
      const response = await generateStripePaymentLink(token, "ACH", invoiceId);
      if (response.remote === "success") {
        if (response?.data?.data?.paymentLink) {
          window.location.href = response.data.data.paymentLink;
        } else {
          message.error(
            "There is some issues with the payments, Please try again later.",
          );
        }
        setShowACHPayment(false);
      } else {
        message.error(response?.errors?.errors?.error);
      }
    }
    if (paymentMethod === "square") {
      setSquareACHPayment(true);
    }
  };

  const handleCancelQBCard = () => {
    dispatch(
      modalToggleAction({
        qbBillingDetails: false,
      }),
    );
    setBillingEmail("");
    setBillingError("");
  };

  const handleQBPayment = async ({ type }) => {
    const invoiceId = depositPopupDetail?.invoiceSettings?.invoice?._id;
    setBtnLoading(true);
    if (billingEmail) {
      const response = await generateQBPaymentLink({
        token,
        type,
        invoiceId,
        billingEmail,
      });
      if (response.remote === "success") {
        if (response?.data?.data?.link) {
          openLinkNewTab(response.data.data.link);
        } else {
          message.error(
            "There is some issues with the payments, Please try again later.",
          );
        }
        setBillingEmail("");
        setBillingError("");
        if (type === "CARD") {
          dispatch(
            modalToggleAction({
              qbBillingDetails: false,
            }),
          );
        }
        if (type === "ACH") {
          dispatch(
            modalToggleAction({
              qbBillingDetails: false,
            }),
          );
        }
        setBtnLoading(false);

        // if (window) window.open(response.data.data.link, "_blank").focus();
        // window.location.href = response.data.data.link;
      } else {
        message.error(response?.errors?.errors?.error);
        setBtnLoading(false);
      }
    } else {
      setBillingError("Please enter billing email");
      setBtnLoading(false);
    }
  };

  const handleBillingEmailChange = (e) => {
    const { value } = e?.target;
    setBillingEmail(value);
    setBillingError(
      !value
        ? "Please enter billing email"
        : !regex.emailRegex.test(value)
        ? "Billing email is invalid"
        : "",
    );
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setDepositPopupDetail((prev) => ({
      ...prev,
      invoiceSettings: {
        ...prev.invoiceSettings,
        openModal: false,
      },
    }));
  };

  const handleCancelSquareAch = () => {
    setSquareACHPayment(false);
  };

  const handleTokenizeResponse = async (token) => {
    const authToken = new URLSearchParams(location.search).get("token");
    const data = {
      paymentToken: token?.token,
    };
    const response = await getSquareCharge(
      authToken,
      data,
      depositPopupDetail?.invoiceSettings?.invoice?._id,
    );
    if (response.remote === "success") {
      message.success(
        "Payment made successful, please wait for the bank to confirm the payment",
      );
      setSquareACHPayment(false);
      setPaymentLoading(false);
    }
  };

  const openPopups = () => {
    setIsModalVisible(false);
    const paymentIntegration =
      selectedProposalSettings?.organizationDetails?.invoiceSettings
        ?.paymentIntegration;
    const isPaymentIntegrated =
      depositPopupDetail?.invoiceSettings?.paymentIntegration?.[
        depositPopupDetail?.invoiceSettings?.paymentIntegration
          ?.activeIntegration
      ]?.accountId;
    if (
      paymentIntegration?.allowCardPayment &&
      paymentIntegration?.allowAchPayment &&
      isPaymentIntegrated
    ) {
      setShowPaymentOptions(true);
    }
    if (
      paymentIntegration?.allowCardPayment &&
      !paymentIntegration?.allowAchPayment &&
      isPaymentIntegrated
    ) {
      setShowCardPayment(true);
    }
    if (
      !paymentIntegration?.allowCardPayment &&
      paymentIntegration?.allowAchPayment &&
      isPaymentIntegrated
    ) {
      setShowACHPayment(true);
    }
    if (!isPaymentIntegrated) {
      setIsModalVisible(true);
    }
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const viewEstimate = async () => {
    setCallingView(true);
    setViewed(false);
    if (selectedProposalSettings?.estimateDetails?._id && !isEstimationViewed) {
      const response = await viewedEstimation(
        selectedProposalSettings?.estimateDetails?._id,
      );
      if (response.remote === "success") {
        localStorage.setItem(
          selectedProposalSettings?.estimateDetails?._id,
          "true",
        );
      }
      setCallingView(false);
    }
  };

  const getOrganizationInfo = async () => {
    if (selectedProposalSettings?.estimateId || estimationId) {
      const response = await getOrganizationDetails({
        estimateId: token ? selectedProposalSettings?.estimateId : estimationId,
      });
      if (response.remote === "success") {
        setOrganizationInfo(response?.data?.data);
      }
    }
  };

  useEffect(() => {
    if (!token) getOrganizationInfo();
  }, [selectedProposalSettings]);

  useEffect(() => {
    if (
      selectedProposalSettings?.estimateDetails?._id &&
      token &&
      !viewed &&
      !isEstimationViewed
    ) {
      setViewed(
        localStorage.getItem(selectedProposalSettings?.estimateDetails?._id),
      );
    }
  }, [selectedProposalSettings?.estimateDetails]);

  useEffect(() => {
    if (
      depositPopupDetail?.invoiceSettings?.invoice?._id &&
      depositPopupDetail?.invoiceSettings?.activePaymentIntegration === "square"
    ) {
      dispatch(
        getSquareInfoAction(
          token,
          depositPopupDetail?.invoiceSettings?.invoice?._id,
        ),
      );
    }
  }, [depositPopupDetail]);

  return loading ? (
    <SmallLoader />
  ) : (
    <>
      <GlobalStyle />
      <div className="contract-preview-box">
        <div
          className="sticky-sidebar mobile-view-proposal-header"
          {...{ ...(token && { style: { top: 0 } }) }}
        >
          <ProposalHeader
            getProposalSettings={getProposalSettings}
            selectedProposal={selectedProposal}
            selectedProposalSettings={selectedProposalSettings}
            contractorSigningContract={contractorSigningContract}
            setContractorSigningContract={setContractorSigningContract}
            setSelectedProposalSettings={setSelectedProposalSettings}
            setProposalList={setProposalList}
            generatingPdf={generatingPdf}
            setGeneratingPdf={setGeneratingPdf}
            contentRef={contentRef}
            contractorSignature={contractorSignature}
            checkingSignature={checkingSignature}
          />
        </div>
        <div>
          <Row
            gutter={token ? [] : { xs: 0, lg: 24 }}
            {...{ ...(token && { style: { justifyContent: "center" } }) }}
          >
            {!token && (
              <Col xs={24} lg={6} style={{ position: "relative" }}>
                <div className="sticky-sidebar">
                  <Card
                    bodyStyle={{ padding: "12px" }}
                    style={{
                      border: "0px",
                      borderRadius: "12px",
                      height: "100%",
                    }}
                    className="proposal_mobile_header_card"
                  >
                    <ProposalSettings
                      proposalList={proposalList}
                      selectedProposal={selectedProposal}
                      settings={{ ...selectedProposalSettings?.settings }}
                      handleChangeToggle={handleChangeToggle}
                      switchLoading={switchLoading}
                      setEditProposalSettings={setEditProposalSettings}
                      setSelectedProposal={setSelectedProposal}
                      proposalDetails={selectedProposalSettings}
                      setSelectedProposalSettings={setSelectedProposalSettings}
                      getProposalList={getProposalList}
                    />
                  </Card>
                </div>
              </Col>
            )}
            <Col xs={24} lg={18} style={{ position: "relative" }}>
              <div
                className="sticky-sidebar desktop-view-proposal-header"
                {...{ ...(token && { style: { top: 0 } }) }}
              >
                <ProposalHeader
                  getProposalSettings={getProposalSettings}
                  selectedProposal={selectedProposal}
                  selectedProposalSettings={selectedProposalSettings}
                  contractorSigningContract={contractorSigningContract}
                  setContractorSigningContract={setContractorSigningContract}
                  setSelectedProposalSettings={setSelectedProposalSettings}
                  setProposalList={setProposalList}
                  generatingPdf={generatingPdf}
                  setGeneratingPdf={setGeneratingPdf}
                  contentRef={contentRef}
                  handleDownloadPdf={handleDownloadPdf}
                />
              </div>
              <div className="px-4 py-3 max-width-832 mx-auto">
                <div id="divToPrint" ref={contentRef}>
                  {/* Cover page */}
                  {selectedProposalSettings?.settings?.coverPage && (
                    <ProposalCoverPage
                      coverPageDetails={selectedProposalSettings?.coverPage}
                      settings={selectedProposalSettings?.settings}
                      proposalDetails={selectedProposalSettings}
                      generatingPdf={generatingPdf}
                    />
                  )}
                  {/* About us */}
                  {selectedProposalSettings?.settings?.aboutUs && (
                    <ProposalAboutUs
                      coverPageDetails={selectedProposalSettings?.coverPage}
                      settings={selectedProposalSettings?.settings}
                      proposalDetails={selectedProposalSettings}
                      setEditProposalSettings={setEditProposalSettings}
                      generatingPdf={generatingPdf}
                    />
                  )}
                  {/* Past projects */}
                  {selectedProposalSettings?.settings?.pastProject && (
                    <ProposalPastProjects
                      coverPageDetails={selectedProposalSettings?.coverPage}
                      settings={selectedProposalSettings?.settings}
                      proposalDetails={selectedProposalSettings}
                      generatingPdf={generatingPdf}
                    />
                  )}
                  {/* Estimate */}
                  <ProposalEstimate
                    settings={selectedProposalSettings?.settings}
                    proposalDetails={selectedProposalSettings}
                    setProposalDetails={setSelectedProposalSettings}
                    generatingPdf={generatingPdf}
                    organizationInfo={organizationInfo}
                  />
                  {/* Summary */}
                  {selectedProposalSettings?.settings?.summary?.details
                    ?.lineItems?.active && (
                    <ProposalSummary
                      settings={selectedProposalSettings?.settings}
                      proposalDetails={selectedProposalSettings}
                      generatingPdf={generatingPdf}
                    />
                  )}
                  {/* Payment Terms */}
                  {selectedProposalSettings?.settings?.paymentTerms && (
                    <ProposalPaymentTerms
                      settings={selectedProposalSettings?.settings}
                      proposalDetails={selectedProposalSettings}
                      generatingPdf={generatingPdf}
                    />
                  )}
                  {/* Next steps */}
                  {selectedProposalSettings?.settings?.nextSteps &&
                    !selectedProposalSettings?.settings?.paymentTerms && (
                      <ProposalNextStep
                        settings={selectedProposalSettings?.settings}
                        proposalDetails={selectedProposalSettings}
                        userDetails={userDetails}
                        generatingPdf={generatingPdf}
                      />
                    )}
                  {/* Proposed Schedule */}
                  {selectedProposalSettings?.settings?.proposedSchedule && (
                    <ProposalProposedSchedule
                      settings={selectedProposalSettings?.settings}
                      proposalDetails={selectedProposalSettings}
                      generatingPdf={generatingPdf}
                    />
                  )}
                  {/* Financing */}
                  {selectedProposalSettings?.settings?.financing &&
                    !selectedProposalSettings?.settings?.proposedSchedule && (
                      <ProposalFinancing
                        settings={selectedProposalSettings?.settings}
                        proposalDetails={selectedProposalSettings}
                        generatingPdf={generatingPdf}
                        userDetails={userDetails}
                      />
                    )}
                  {/* Terms & Conditions */}
                  {selectedProposalSettings?.settings?.termsAndConditions && (
                    <ProposalTermsNCondition
                      settings={selectedProposalSettings?.settings}
                      proposalDetails={selectedProposalSettings}
                      generatingPdf={generatingPdf}
                    />
                  )}
                  {selectedProposalSettings?.settings?.attachments && (
                    <ProposalAttachments
                      settings={selectedProposalSettings?.settings}
                      proposalDetails={selectedProposalSettings}
                      generatingPdf={generatingPdf}
                    />
                  )}
                  {/* Signature */}
                  {selectedProposalSettings?.settings?.signature && (
                    <ProposalSignature
                      settings={selectedProposalSettings?.settings}
                      proposalDetails={selectedProposalSettings}
                      customerSignature={customerSignature}
                      generatingPdf={generatingPdf}
                      contractorSignature={contractorSignature}
                      compareUrlWithBase64={compareUrlWithBase64}
                      setESignatureOpenFor={setESignatureOpenFor}
                      setShowCustomerSignatureWarning={
                        setShowCustomerSignatureWarning
                      }
                      setContractorSignature={setContractorSignature}
                      SIGNATURE_FOR={SIGNATURE_FOR}
                      editable={editable}
                      organizationInfo={organizationInfo}
                    />
                  )}
                  {/* Certification */}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <CustomModal
        isModalOpen={editProposalSettings?.templateColor}
        handleCancel={() => setEditProposalSettings({ templateColor: false })}
        width={changingColor ? 450 : 400}
        title="Colors"
        centered
      >
        <ChromePicker
          color={color || selectedProposalSettings?.settings?.templateColor}
          onChange={(color) => handleColorChange(color)}
          disableAlpha
        />
        <div className="d-flex align-items-center w-100 gap-2 justify-content-between">
          <Button
            onClick={() => setTemplateColor()}
            loading={changingColor}
            disabled={changingColor}
            className="clear-filter mt-2"
          >
            Set Temporary Color
          </Button>
          <Button
            loading={changingColor}
            disabled={changingColor}
            className="apply-btn mt-2"
            type="primary"
            onClick={() => setTemplateColor(true)}
          >
            Set Template Color
          </Button>
        </div>
      </CustomModal>
      {eSignatureOpenFor && (
        <ESignature
          isOpen={!!eSignatureOpenFor}
          onClose={() => setESignatureOpenFor("")}
          onSave={onSignatureSave}
          title={eSignatureOpenFor}
          requiredFullName
          organizationSignature={organizationLogoBase64}
          contractorName={currentUserDetail.name}
          isDefault={eSignatureOpenFor === SIGNATURE_FOR.CONTRACTOR}
        />
      )}
      <Modal
        open={showCustomerSignatureWarning}
        onCancel={() => setShowCustomerSignatureWarning(false)}
        title="Warning"
        footer={
          <>
            <Button onClick={() => setShowCustomerSignatureWarning(false)}>
              OK
            </Button>
          </>
        }
      >
        {editable
          ? "The Contractor’s signature is required before the Customer can sign. Please sign the Estimate as the Contractor before continuing with the Customer's signature."
          : "The Contractor Signature is required prior to you signing the estimate. Please reach out to the Contractor if you are ready to sign."}
      </Modal>
      <CustomModal
        isModalOpen={editProposalSettings?.coverPage}
        handleCancel={() => setEditProposalSettings({ coverPage: false })}
        width={544}
        height={418}
        title="Edit Cover Page"
        closeIcon={<>{closex}</>}
        destroyOnClose
      >
        <EditCoverPageForm
          handleCancel={() => setEditProposalSettings({ coverPage: false })}
          proposalDetails={selectedProposalSettings}
          setProposalDetails={setSelectedProposalSettings}
        />
      </CustomModal>
      <CustomModal
        isModalOpen={editProposalSettings?.aboutUs}
        handleCancel={() => setEditProposalSettings({ aboutUs: false })}
        width={816}
        title="Edit About Us"
        closeIcon={<></>}
        destroyOnClose
      >
        <EditAboutUsForm
          handleCancel={() => setEditProposalSettings({ aboutUs: false })}
          proposalDetails={selectedProposalSettings}
          setProposalDetails={setSelectedProposalSettings}
        />
      </CustomModal>
      <CustomModal
        isModalOpen={editProposalSettings?.pastProjects}
        handleCancel={() => setEditProposalSettings({ pastProjects: false })}
        width={816}
        title="Edit Past Projects"
        closeIcon={<></>}
        destroyOnClose
      >
        <EditPastProjectForm
          handleCancel={() => setEditProposalSettings({ pastProjects: false })}
          proposalDetails={selectedProposalSettings}
          setProposalDetails={setSelectedProposalSettings}
        />
      </CustomModal>
      <CustomModal
        isModalOpen={editProposalSettings?.nextSteps}
        handleCancel={() => setEditProposalSettings({ nextSteps: false })}
        width={544}
        title="Edit Next Steps"
        closeIcon={<></>}
      >
        <EditNextStepsForm
          handleCancel={() => setEditProposalSettings({ nextSteps: false })}
          proposalDetails={selectedProposalSettings}
          setProposalDetails={setSelectedProposalSettings}
        />
      </CustomModal>
      <CustomModal
        isModalOpen={editProposalSettings?.proposedSchedule}
        handleCancel={() =>
          setEditProposalSettings({ proposedSchedule: false })
        }
        width={544}
        title="Edit Proposed Schedule"
        closeIcon={<></>}
      >
        <EditProposedScheduleForm
          handleCancel={() =>
            setEditProposalSettings({ proposedSchedule: false })
          }
          proposalDetails={selectedProposalSettings}
          setProposalDetails={setSelectedProposalSettings}
        />
      </CustomModal>
      <CustomModal
        isModalOpen={editProposalSettings?.financing}
        handleCancel={() => setEditProposalSettings({ financing: false })}
        width={544}
        title="Edit Financing"
        closeIcon={<></>}
      >
        <EditFinancingForm
          handleCancel={() => setEditProposalSettings({ financing: false })}
          proposalDetails={selectedProposalSettings}
          setProposalDetails={setSelectedProposalSettings}
        />
      </CustomModal>
      <CustomModal
        isModalOpen={editProposalSettings?.termsAndConditions}
        handleCancel={() =>
          setEditProposalSettings({ termsAndConditions: false })
        }
        width={1200}
        title="Edit Terms And Conditions"
        closeIcon={<></>}
      >
        <EditTermsAndConditionsForm
          handleCancel={() =>
            setEditProposalSettings({ termsAndConditions: false })
          }
          proposalDetails={selectedProposalSettings}
          setProposalDetails={setSelectedProposalSettings}
        />
      </CustomModal>
      <CustomModal
        isModalOpen={editProposalSettings?.attachments}
        handleCancel={() => setEditProposalSettings({ attachments: false })}
        width={544}
        title="Edit Attachment"
        destroyOnClose
      >
        <EditAttachmentUpload
          handleCancel={() => setEditProposalSettings({ attachments: false })}
          proposalDetails={selectedProposalSettings}
          setProposalDetails={setSelectedProposalSettings}
        />
      </CustomModal>
      <CustomModal
        name="emilOpen"
        isModalOpen={depositPopupDetail?.invoiceSettings?.openModal}
        closable={false}
        width={800}
      >
        <EmailModal
          action="sign-contract"
          handleClose={handleCancel}
          title={
            depositPopupDetail?.invoiceSettings?.depositPopupTemplate
              ?.popUpTemplate?.title
          }
          welcomeNote={
            depositPopupDetail?.invoiceSettings?.depositPopupTemplate
              ?.popUpTemplate?.welcomeNote
          }
          body={
            depositPopupDetail?.invoiceSettings?.depositPopupTemplate
              ?.popUpTemplate?.body
          }
          payWithCheckCash={
            depositPopupDetail?.invoiceSettings?.depositPopupTemplate
              ?.payWithCheckCash
          }
          payWithCreditCardAch={
            depositPopupDetail?.invoiceSettings?.depositPopupTemplate
              ?.payWithCreditCardAch
          }
          invoice={depositPopupDetail?.invoiceSettings?.invoice || {}}
          payWithCheckCashNote={
            depositPopupDetail?.invoiceSettings?.depositPopupTemplate
              ?.payWithCheckCashNote
          }
          openPopups={openPopups}
        />
      </CustomModal>
      <PayDepositePopup
        isModalVisible={showPaymentOptions}
        handleCardPaymentCancel={handleCardPaymentCancel}
        handleACHPaymentCancel={handleACHPaymentCancel}
        setIsModalVisible={setIsModalVisible}
        setShowPaymentOptions={setShowPaymentOptions}
        paymentIntegration={
          depositPopupDetail?.invoiceSettings?.paymentIntegration?.[
            depositPopupDetail?.invoiceSettings?.paymentIntegration
              ?.activeIntegration
          ]?.accountId
        }
      />
      <CreditCardPaymentPopup
        showCardPayment={showCardPayment}
        handleCardPaymentCancel={handleCardPaymentCancel}
        setIsModalVisible={setIsModalVisible}
        setShowCardPayment={setShowCardPayment}
      />
      <CardPaymentFeePopup
        showCardPaymentFee={showCardPaymentFee}
        cardPercentage={
          selectedProposalSettings?.organizationDetails?.invoiceSettings
            ?.paymentIntegration?.extraChargeForCardPayment?.chargePercentage
        }
        paymentAmount={addExtraPercentageAmount(
          depositPopupDetail?.invoiceSettings?.invoice?.amount,
          depositPopupDetail?.invoiceSettings?.paymentIntegration
            ?.extraChargeForCardPayment?.chargePercentage,
        )}
        handleCardPaymentCancel={handleCardPaymentCancel}
        handleConfirmCardPayment={handleConfirmCardPayment}
        handleBackToCard={handleBackToCard}
      />
      <ACHPaymentPopup
        showACHPayment={showACHPayment}
        handleACHPaymentCancel={handleACHPaymentCancel}
        setIsModalVisible={setIsModalVisible}
        setShowACHPayment={setShowACHPayment}
      />
      <QBBillingDetail
        isModalOpen={modalToggle?.qbBillingDetails}
        handleCancel={handleCancelQBCard}
        billingEmail={billingEmail}
        handleBillingEmailChange={handleBillingEmailChange}
        billingError={billingError}
        type={paymentType}
        handleQBPayment={handleQBPayment}
        loading={btnLoading}
      />
      <SquareAchPaymentModal
        isModalOpen={squareACHPayment}
        handleCancel={handleCancelSquareAch}
        locationId={squarePaymentInfo?.locationId}
        handleTokenizeResponse={handleTokenizeResponse}
        loading={paymentLoading}
        setPaymentLoading={setPaymentLoading}
      />
      <Modal
        title={false}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        className="custom-modal"
        closable={false}
        width={542}
        centered
      >
        <div className="d-flex gap-3">
          {payCash}
          <div className="flex-fill">
            <h2 className="pay-title">To pay with a Check or Cash</h2>
            <div
              className="w-100 text-content"
              style={{
                borderRadius: 8,
                maxWidth: 600,
                minHeight: 150,
                width: "100%",
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    depositPopupDetail?.invoiceSettings?.depositPopupTemplate?.payWithCheckCashNote?.replace(
                      /@/g,
                      "",
                    ),
                }}
              />
            </div>
          </div>
        </div>
        <div className="text-end pt-4 d-flex align-items-center gap-2 justify-content-end flex-column flex-sm-row">
          {(depositPopupDetail?.invoiceSettings?.paymentIntegration?.stripe
            ?.accountId ||
            depositPopupDetail?.invoiceSettings?.paymentIntegration?.quickbooks
              ?.accountId) && (
            <Button className="clear-filter" onClick={() => openPopups()}>
              Back
            </Button>
          )}
          <Button
            className="apply-btn mobile-width"
            type="primary"
            onClick={handleClose}
          >
            Confirm
          </Button>
        </div>
      </Modal>
      <ModalDailog isModalOpen={viewed} closeIcon={<></>}>
        <Row style={{ justifyContent: "center" }}>
          <Col>
            <div>
              {selectedProposalSettings?.organizationDetails?.logo && (
                <img
                  src={createValidUrl(
                    selectedProposalSettings?.organizationDetails?.logo,
                  )}
                  alt="Company logo"
                  style={{ width: 200 }}
                />
              )}
            </div>
            <div>
              <p style={{ fontSize: 20, fontWeight: "bold" }}>
                Your Proposal From{" "}
                {selectedProposalSettings?.organizationDetails?.name}
              </p>
            </div>
            <div>
              <Button
                type="primary"
                onClick={() => viewEstimate()}
                style={{ margin: "0 auto" }}
                disabled={callingView}
              >
                View Proposal
              </Button>
            </div>
          </Col>
        </Row>
      </ModalDailog>
    </>
  );
}
